export var montageDataRows0 = [
  {
    radsatzmontageDatum: "25.04.10",
    montageort: "Stadler Winterthur",
    radsatzwellenNr: "444188",
    radscheibeLinks: "PQI90RSN1FF",
    aufpressdruckRadscheibeLinks: "18450469-3",
    radscheibeRechts: "FUG26XRP6EB",
    aufpressdruckRadscheibeRechts: "630817-1",
    bremsscheibeRechts: "CNJ30NMF3VR",
    aufpressdruckBremsscheibeRechts: "47.847.994",
    bremsscheibeLinks: "QXD43QTV0LF",
    aufpressdruckBremsscheibeLinks: "17.363.521",
    lagerLinks: "239436536",
    aufpressdruckLagerLinks: "OOM22BWS3AQ",
    lagerRechts: "3559938K",
    aufpressdruckLagerRechts: "BSK63PVH6KA",
  },
  {
    radsatzmontageDatum: "06.01.16",
    montageort: "Stadler Ungarn",
    radsatzwellenNr: "239650",
    radscheibeLinks: "TYD17VEY2KK",
    aufpressdruckRadscheibeLinks: "28152490-9",
    radscheibeRechts: "NSL18MBY5NI",
    aufpressdruckRadscheibeRechts: "47593840-2",
    bremsscheibeRechts: "EKD22XXT9AI",
    aufpressdruckBremsscheibeRechts: "14.768.895",
    bremsscheibeLinks: "TCM64XIE8LT",
    aufpressdruckBremsscheibeLinks: "23.154.422",
    lagerLinks: "333164566",
    aufpressdruckLagerLinks: "DFX31TIV4LG",
    lagerRechts: "35657266",
    aufpressdruckLagerRechts: "BQF55YSZ8PH",
  },
  {
    radsatzmontageDatum: "23.07.19",
    montageort: "Stadler Ungarn",
    radsatzwellenNr: "750963",
    radscheibeLinks: "CTY02HQH2QC",
    aufpressdruckRadscheibeLinks: "37882296-3",
    radscheibeRechts: "CEF46DVT9CC",
    aufpressdruckRadscheibeRechts: "7465788-5",
    bremsscheibeRechts: "ANM54HDN0FJ",
    aufpressdruckBremsscheibeRechts: "2.854.948",
    bremsscheibeLinks: "WGG38VUW2MX",
    aufpressdruckBremsscheibeLinks: "10.752.102",
    lagerLinks: "152693028",
    aufpressdruckLagerLinks: "ILL12GYP0CQ",
    lagerRechts: "377727584",
    aufpressdruckLagerRechts: "TIJ53IZM5BA",
  },
  {
    radsatzmontageDatum: "12.03.21",
    montageort: "Stadler Winterthur",
    radsatzwellenNr: "633749",
    radscheibeLinks: "RDS33QQU3CE",
    aufpressdruckRadscheibeLinks: "32170229-5",
    radscheibeRechts: "JUE32MPE9XH",
    aufpressdruckRadscheibeRechts: "41267233-K",
    bremsscheibeRechts: "UII65EQC0EQ",
    aufpressdruckBremsscheibeRechts: "46.192.222",
    bremsscheibeLinks: "YCS42WSW5UJ",
    aufpressdruckBremsscheibeLinks: "12.243.527",
    lagerLinks: "458819076",
    aufpressdruckLagerLinks: "WKL70IWO9BP",
    lagerRechts: "26292176K",
    aufpressdruckLagerRechts: "NTB47QLS5HC",
  },
  {
    radsatzmontageDatum: "28.08.22",
    montageort: "Stadler Winterthur",
    radsatzwellenNr: "912052",
    radscheibeLinks: "IYL23MFC0PC",
    aufpressdruckRadscheibeLinks: "11764616-5",
    radscheibeRechts: "ULJ53RQQ8UJ",
    aufpressdruckRadscheibeRechts: "24929128-5",
    bremsscheibeRechts: "RDD45OTS4AD",
    aufpressdruckBremsscheibeRechts: "14.735.371",
    bremsscheibeLinks: "MIZ40TVL6VG",
    aufpressdruckBremsscheibeLinks: "3.989.830",
    lagerLinks: "23265370",
    aufpressdruckLagerLinks: "NUE36LFK0XK",
    lagerRechts: "162141872",
    aufpressdruckLagerRechts: "TSW26KPX7IG",
  },
];
export var montageDataRows1 = [
  {
    radsatzmontageDatum: "26.05.11",
    montageort: "Stadler Winterthur",
    radsatzwellenNr: "427841",
    radscheibeLinks: "AZL95CAK1VW",
    aufpressdruckRadscheibeLinks: "16920544-2",
    radscheibeRechts: "JBL38XGU6TC",
    aufpressdruckRadscheibeRechts: "9915979-0",
    bremsscheibeRechts: "DYE72DKV3KK",
    aufpressdruckBremsscheibeRechts: "14.558.155",
    bremsscheibeLinks: "DVX23VQH4GU",
    aufpressdruckBremsscheibeLinks: "35.260.922",
    lagerLinks: "468966859",
    aufpressdruckLagerLinks: "RYE72JFP7VE",
    lagerRechts: "192469082",
    aufpressdruckLagerRechts: "XNT29RTO0TE",
  },
  {
    radsatzmontageDatum: "06.01.16",
    montageort: "Stadler Winterthur",
    radsatzwellenNr: "654114",
    radscheibeLinks: "MOF66GOD8JH",
    aufpressdruckRadscheibeLinks: "1814764-5",
    radscheibeRechts: "RKE33DLO9KC",
    aufpressdruckRadscheibeRechts: "18648735-4",
    bremsscheibeRechts: "SEC65XBG6ZD",
    aufpressdruckBremsscheibeRechts: "20.286.102",
    bremsscheibeLinks: "UOL14UJD1TR",
    aufpressdruckBremsscheibeLinks: "9.131.748",
    lagerLinks: "306936638",
    aufpressdruckLagerLinks: "JWV58MOF8UW",
    lagerRechts: "435253K",
    aufpressdruckLagerRechts: "OPZ66WRV3WD",
  },
  {
    radsatzmontageDatum: "16.03.18",
    montageort: "Stadler Ungarn",
    radsatzwellenNr: "999878",
    radscheibeLinks: "PAK33VYU3HJ",
    aufpressdruckRadscheibeLinks: "20480830-9",
    radscheibeRechts: "PUT02RVK1CX",
    aufpressdruckRadscheibeRechts: "27816678-3",
    bremsscheibeRechts: "GBC20RPC9UC",
    aufpressdruckBremsscheibeRechts: "36.635.907",
    bremsscheibeLinks: "FUC16PKX1KU",
    aufpressdruckBremsscheibeLinks: "47.447.486",
    lagerLinks: "72072383",
    aufpressdruckLagerLinks: "HIF41USO7ZQ",
    lagerRechts: "29516347K",
    aufpressdruckLagerRechts: "ZKL79CXJ8BS",
  },
  {
    radsatzmontageDatum: "09.07.20",
    montageort: "Stadler Ungarn",
    radsatzwellenNr: "594309",
    radscheibeLinks: "MLA71IGE9MY",
    aufpressdruckRadscheibeLinks: "35555197-0",
    radscheibeRechts: "WLE75RPR7JY",
    aufpressdruckRadscheibeRechts: "21730360-5",
    bremsscheibeRechts: "YCV81YIJ0XN",
    aufpressdruckBremsscheibeRechts: "16.907.813",
    bremsscheibeLinks: "FNN85NDR0ET",
    aufpressdruckBremsscheibeLinks: "18.970.568",
    lagerLinks: "2979810",
    aufpressdruckLagerLinks: "VNW38TCO5XN",
    lagerRechts: "345138420",
    aufpressdruckLagerRechts: "BIO59YUS0BW",
  },
];
export var montageDataRows2 = [
  {
    radsatzmontageDatum: "29.07.13",
    montageort: "Stadler Winterthur",
    radsatzwellenNr: "680848",
    radscheibeLinks: "QPE28VIL6IV",
    aufpressdruckRadscheibeLinks: "4443532-2",
    radscheibeRechts: "NBT39VME0MF",
    aufpressdruckRadscheibeRechts: "41176-0",
    bremsscheibeRechts: "RKY15WRC1DT",
    aufpressdruckBremsscheibeRechts: "17.807.268",
    bremsscheibeLinks: "IWX52GLP0XE",
    aufpressdruckBremsscheibeLinks: "43.165.350",
    lagerLinks: "54775644",
    aufpressdruckLagerLinks: "YZV18VVQ6LK",
    lagerRechts: "10226317",
    aufpressdruckLagerRechts: "QHX17HOM7OY",
  },
  {
    radsatzmontageDatum: "01.06.15",
    montageort: "Stadler Winterthur",
    radsatzwellenNr: "653599",
    radscheibeLinks: "CWY83YLZ5GT",
    aufpressdruckRadscheibeLinks: "7710339-2",
    radscheibeRechts: "LCY01WXF5FG",
    aufpressdruckRadscheibeRechts: "15380845-7",
    bremsscheibeRechts: "DHF22SFU9NN",
    aufpressdruckBremsscheibeRechts: "3.483.141",
    bremsscheibeLinks: "XOF77VSA2UN",
    aufpressdruckBremsscheibeLinks: "17.155.925",
    lagerLinks: "99974907",
    aufpressdruckLagerLinks: "JJI53TZL3GW",
    lagerRechts: "232101164",
    aufpressdruckLagerRechts: "DOH65TIG5UF",
  },
  {
    radsatzmontageDatum: "17.11.17",
    montageort: "Stadler Ungarn",
    radsatzwellenNr: "17462",
    radscheibeLinks: "CSL24TQO1TP",
    aufpressdruckRadscheibeLinks: "32751413-K",
    radscheibeRechts: "QPW17VFH7PD",
    aufpressdruckRadscheibeRechts: "2188125-2",
    bremsscheibeRechts: "JBN76JTG0AF",
    aufpressdruckBremsscheibeRechts: "16.342.675",
    bremsscheibeLinks: "BPY29GWB2JX",
    aufpressdruckBremsscheibeLinks: "31.812.239",
    lagerLinks: "414164439",
    aufpressdruckLagerLinks: "FHX02FUV3XP",
    lagerRechts: "47341558",
    aufpressdruckLagerRechts: "UJR27EVG7CP",
  },
  {
    radsatzmontageDatum: "11.05.19",
    montageort: "Stadler Ungarn",
    radsatzwellenNr: "890923",
    radscheibeLinks: "UXX98PRG7MV",
    aufpressdruckRadscheibeLinks: "46637292-7",
    radscheibeRechts: "GLJ47PYY9WU",
    aufpressdruckRadscheibeRechts: "10459176-0",
    bremsscheibeRechts: "JGE17WQL3TJ",
    aufpressdruckBremsscheibeRechts: "23.545.521",
    bremsscheibeLinks: "EEQ06JIL6HS",
    aufpressdruckBremsscheibeLinks: "50.713.337",
    lagerLinks: "223511759",
    aufpressdruckLagerLinks: "MIQ86CQS2MP",
    lagerRechts: "5804485",
    aufpressdruckLagerRechts: "YRE08WVC9CR",
  },
  {
    radsatzmontageDatum: "16.01.22",
    montageort: "Stadler Winterthur",
    radsatzwellenNr: "981769",
    radscheibeLinks: "DGB59ORY5QU",
    aufpressdruckRadscheibeLinks: "49132377-9",
    radscheibeRechts: "RWF86LQH3CZ",
    aufpressdruckRadscheibeRechts: "49780914-2",
    bremsscheibeRechts: "SSI73SSS4WU",
    aufpressdruckBremsscheibeRechts: "499.299",
    bremsscheibeLinks: "RWJ60KRA9IX",
    aufpressdruckBremsscheibeLinks: "3.224.361",
    lagerLinks: "459064729",
    aufpressdruckLagerLinks: "ZQD66CEP1FY",
    lagerRechts: "331774529",
    aufpressdruckLagerRechts: "RMH02HOH8HZ",
  },
];
export var montageDataRows3 = [
  {
    radsatzmontageDatum: "27.06.12",
    montageort: "Stadler Winterthur",
    radsatzwellenNr: "483743",
    radscheibeLinks: "TAW67NLS4TI",
    aufpressdruckRadscheibeLinks: "19869660-9",
    radscheibeRechts: "TBD57MPK4VY",
    aufpressdruckRadscheibeRechts: "43229437-4",
    bremsscheibeRechts: "WGM49PCX1SK",
    aufpressdruckBremsscheibeRechts: "37.475.338",
    bremsscheibeLinks: "OHM02YCJ6UX",
    aufpressdruckBremsscheibeLinks: "37.902.702",
    lagerLinks: "11417531",
    aufpressdruckLagerLinks: "XWE30BRJ1KP",
    lagerRechts: "413437806",
    aufpressdruckLagerRechts: "IGK58FQT4YO",
  },
  {
    radsatzmontageDatum: "20.11.14",
    montageort: "Stadler Winterthur",
    radsatzwellenNr: "880643",
    radscheibeLinks: "QOK67GSP2US",
    aufpressdruckRadscheibeLinks: "37327855-6",
    radscheibeRechts: "MUN83GIV2TL",
    aufpressdruckRadscheibeRechts: "28284197-5",
    bremsscheibeRechts: "FDN37VRR3WV",
    aufpressdruckBremsscheibeRechts: "1.580.430",
    bremsscheibeLinks: "KFL63DYC4TK",
    aufpressdruckBremsscheibeLinks: "8.700.375",
    lagerLinks: "6413706",
    aufpressdruckLagerLinks: "FDE38KFX3HJ",
    lagerRechts: "337851541",
    aufpressdruckLagerRechts: "GPG24PAS7FV",
  },
  {
    radsatzmontageDatum: "08.04.17",
    montageort: "Stadler Ungarn",
    radsatzwellenNr: "241455",
    radscheibeLinks: "XTJ27WXR3MS",
    aufpressdruckRadscheibeLinks: "20747390-1",
    radscheibeRechts: "BRH62HJZ6VH",
    aufpressdruckRadscheibeRechts: "31680958-8",
    bremsscheibeRechts: "SMV72MUP7GG",
    aufpressdruckBremsscheibeRechts: "41.437.340",
    bremsscheibeLinks: "DJL36KRM2BK",
    aufpressdruckBremsscheibeLinks: "39.988.298",
    lagerLinks: "304399368",
    aufpressdruckLagerLinks: "PQT02ESZ0WV",
    lagerRechts: "813494",
    aufpressdruckLagerRechts: "BZM13WID8YD",
  },
  {
    radsatzmontageDatum: "05.09.19",
    montageort: "Stadler Ungarn",
    radsatzwellenNr: "846768",
    radscheibeLinks: "ESH88DOL6EO",
    aufpressdruckRadscheibeLinks: "3569157-K",
    radscheibeRechts: "KXY54LYJ1GX",
    aufpressdruckRadscheibeRechts: "15164414-7",
    bremsscheibeRechts: "BEY65MBA3XI",
    aufpressdruckBremsscheibeRechts: "25.369.659",
    bremsscheibeLinks: "KNW86QWX9MG",
    aufpressdruckBremsscheibeLinks: "5.770.599",
    lagerLinks: "388621044",
    aufpressdruckLagerLinks: "PLY06UGN2QE",
    lagerRechts: "23715908K",
    aufpressdruckLagerRechts: "NOV80VPD4UL",
  },
  {
    radsatzmontageDatum: "08.07.21",
    montageort: "Stadler Winterthur",
    radsatzwellenNr: "826111",
    radscheibeLinks: "SOR26SFL0JD",
    aufpressdruckRadscheibeLinks: "33669814-6",
    radscheibeRechts: "GUY48XMJ1PN",
    aufpressdruckRadscheibeRechts: "23309506-0",
    bremsscheibeRechts: "OGX79UOJ2WT",
    aufpressdruckBremsscheibeRechts: "11.283.127",
    bremsscheibeLinks: "HEH57PUP6SL",
    aufpressdruckBremsscheibeLinks: "924.854",
    lagerLinks: "46149645",
    aufpressdruckLagerLinks: "TVD55KXX7GV",
    lagerRechts: "268732314",
    aufpressdruckLagerRechts: "SKW57LUV3GK",
  },
];
