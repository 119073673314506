import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { Item } from "./Item";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import {
  Button,
  Box,
  TableContainer,
  TableCell,
  TableRow,
  TableFooter,
  Table,
  TableBody,
  Collapse,
} from "@mui/material";
import { useTranslation, i18n } from "react-i18next";

const RadsatzRow = (props) => {
  const {
    rows,
    rowValue,
    openLagers,
    radsatzRowValue,
    handleClickPickAnotherLager,
    pairValueColor,
    handleOpenMontage,
  } = props;
  const [openRadsatzsDetails, setOpenRadsatzsDetails] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <>
      <TableRow key={rowValue.name}>
        <TableCell
          style={{
            lineHeight: "1",
            padding: "1px 0px 1px 0vw",
            border: "none",
            width: "30rem",
          }}
        >
          <Button
            size="small"
            onClick={(e) => {
              setOpenRadsatzsDetails(!openRadsatzsDetails);
            }}
            style={
              rowValue.value === rows[9].value
                ? {
                    fontSize: "11px",
                    fontWeight: "bold",
                    color: "black",
                  }
                : {
                    fontSize: "11px",
                    color: "black",
                  }
            }
          >
            {rowValue.value}
          </Button>
        </TableCell>
        <TableCell
          style={
            rowValue.value !== ""
              ? {
                  padding: 0,
                  border: "none",
                }
              : { display: "none" }
          }
        >
          <IconButton color="default" onClick={handleOpenMontage}>
            <MoreHorizIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow style={openLagers ? { display: "none" } : null}>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            border: "none",
            fontSize: "11px",
            display: openRadsatzsDetails ? "unset" : "none",
            textAlign: "center",
          }}
          colSpan={1}
        >
          {t("zu-dem-gehören-die-Lagers")}
        </TableCell>
        <TableCell
          style={{
            padding: 0,
            border: "none",
          }}
          colSpan={6}
        >
          <Collapse in={openRadsatzsDetails} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 0,
                align: "right",
              }}
            >
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {rows.map((rowValue) => {
                    if (rowValue.name.includes(radsatzRowValue)) {
                      return (
                        <>
                          <TableRow key={rowValue.id}>
                            <TableCell
                              style={
                                rowValue.value === pairValueColor &&
                                rowValue.value !== ""
                                  ? {
                                      lineHeight: "1",
                                      padding: "1px 0px 1px 0vw",
                                      border: "none",
                                      backgroundColor: "#003060",
                                      color: "#ffff !important",
                                    }
                                  : {
                                      lineHeight: "1",
                                      padding: "1px 0px 1px 0vw",
                                      border: "none",
                                      width: "30rem",
                                      fontSize: "11px",
                                    }
                              }
                            >
                              <Button
                                onClick={(e) => {
                                  handleClickPickAnotherLager(
                                    e.target.innerText
                                  );
                                }}
                                size="small"
                                style={
                                  rowValue.subValue1 === pairValueColor
                                    ? {
                                        fontSize: "11px",
                                        fontWeight: "bold",
                                        textTransform: "none",
                                        backgroundColor: "#003060",
                                        color: "#ffff",
                                      }
                                    : {
                                        fontSize: "11px",
                                        textTransform: "none",
                                        color: "black",
                                      }
                                }
                              >
                                {rowValue.subValue1}
                              </Button>
                            </TableCell>
                          </TableRow>
                          <TableRow key={rowValue.id}>
                            <TableCell
                              style={{
                                lineHeight: "1",
                                padding: "1px 0px 1px 0vw",
                                border: "none",
                                fontSize: "11px",
                              }}
                            >
                              {" "}
                              <Button
                                onClick={(e) => {
                                  handleClickPickAnotherLager(
                                    e.target.innerText
                                  );
                                }}
                                size="small"
                                style={
                                  rowValue.subValue2 === pairValueColor
                                    ? {
                                        fontSize: "11px",
                                        fontWeight: "bold",
                                        textTransform: "none",
                                        backgroundColor: "#003060",
                                        color: "#ffff",
                                      }
                                    : {
                                        fontSize: "11px",
                                        textTransform: "none",
                                        color: "black",
                                      }
                                }
                              >
                                {rowValue.subValue2}
                              </Button>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
const DrehgestellRow = (props) => {
  const { rows, rowValue, openLagers, drehgestellRowValue, openRadsatzs } =
    props;
  const [openDrehgestellDetails, setOpenDrehgestellDetails] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <>
      <TableRow key={rowValue.name}>
        <TableCell
          style={{
            lineHeight: "1",
            padding: "1px 0px 1px 0vw",
            border: "none",
            width: "30rem",
          }}
        >
          <Button
            size="small"
            onClick={(e) => {
              setOpenDrehgestellDetails(!openDrehgestellDetails);
            }}
            style={
              rowValue.value === rows[14].value
                ? {
                    fontSize: "11px",
                    fontWeight: "bold",
                    color: "black",
                  }
                : {
                    fontSize: "11px",
                    color: "black",
                  }
            }
          >
            {rowValue.value}
          </Button>
        </TableCell>
      </TableRow>
      <TableRow style={openLagers || openRadsatzs ? { display: "none" } : null}>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            border: "none",
            fontSize: "11px",
            display: openDrehgestellDetails ? "unset" : "none",
          }}
          colSpan={6}
        >
          {t("zu-dem-gehören-die-Radsatzs")}
        </TableCell>
        <TableCell
          style={{
            padding: 0,
            border: "none",
          }}
          colSpan={6}
        >
          <Collapse in={openDrehgestellDetails} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                align: "right",
              }}
            >
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {rows.map((rowValue) => {
                    if (rowValue.name.includes(drehgestellRowValue)) {
                      return (
                        <>
                          <TableRow key={rowValue.id}>
                            <TableCell
                              style={{
                                lineHeight: "1",
                                padding: "1px 0px 1px 0vw",
                                border: "none",
                                width: "30rem",
                                fontSize: "11px",
                              }}
                            >
                              {rowValue.subValue1}
                            </TableCell>
                          </TableRow>
                          <TableRow key={rowValue.id}>
                            <TableCell
                              style={{
                                lineHeight: "1",
                                padding: "1px 0px 1px 0vw",
                                border: "none",
                                fontSize: "11px",
                              }}
                            >
                              {rowValue.subValue2}
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
export default function StadlerComponent(props) {
  const {
    classes,
    rows,
    progress,
    handleClickPickAnotherLager,
    pairValueColor,
    handleOpenMontage,
  } = props;
  const { t, i18n } = useTranslation();

  const [openLagers, setOpenLagers] = React.useState(false);
  const [openRadsatzs, setOpenRadsatzs] = React.useState(false);
  const [openDrehgestells, setOpenDrehgestells] = React.useState(false);
  return (
    <Item style={{ height: "fit-content !important" }}>
      <Box className={classes.boxIconDatabase}>
        <img
          alt="stadlerIcon"
          src="./stadlerIcon.jpg"
          style={{ height: "25px", width: "250px", marginTop: "10px" }}
        ></img>
        <form style={{ marginTop: "11px" }}></form>
        <CircularProgress
          color="primary"
          sx={
            progress ? { display: "flex", margin: "auto" } : { display: "none" }
          }
        />
        <img
          alt="datenbank"
          src="./datenbank-foto.jpg"
          className={classes.photoDatabase}
        ></img>
      </Box>
      <Box className={classes.boxTable}>
        <Paper className={classes.titleInfo}>{t("Konfiguration")}</Paper>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableBody>
              {rows.map((row) => {
                if (row.name.includes("Lager")) {
                  if (row.name.includes("Haupt")) {
                    return (
                      <>
                        <TableRow key={row.name}>
                          <IconButton
                            aria-label="expand row"
                            size="medium"
                            style={
                              openLagers
                                ? { marginTop: "0.5em" }
                                : { marginTop: "0.5em" }
                            }
                            onClick={() => setOpenLagers(!openLagers)}
                          >
                            {openLagers ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ width: "30rem" }}
                          >
                            {t(row.name.replace("Haupt", ""))}
                          </TableCell>
                          <TableCell style={{ width: 160 }} align="right">
                            {row.value}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              paddingBottom: 0,
                              paddingTop: 0,
                              border: "none",
                            }}
                            colSpan={6}
                          >
                            <Collapse
                              in={openLagers}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box
                                sx={{
                                  margin: 1,
                                  align: "right",
                                  border: "none",
                                }}
                              >
                                <Table size="small" aria-label="purchases">
                                  <TableBody>
                                    {rows.map((rowValue) => {
                                      if (rowValue.name.includes("LagerID")) {
                                        return (
                                          <TableRow key={rowValue.name}>
                                            <TableCell
                                              style={
                                                rowValue.value ===
                                                  pairValueColor &&
                                                rowValue.value !== ""
                                                  ? {
                                                      lineHeight: "1",
                                                      padding:
                                                        "1px 0px 1px 0vw",
                                                      border: "none",
                                                      backgroundColor:
                                                        "#003060",
                                                      color: "#ffff !important",
                                                    }
                                                  : {
                                                      lineHeight: "1",
                                                      padding:
                                                        "1px 0px 1px 0vw",
                                                      border: "none",
                                                    }
                                              }
                                            >
                                              <Button
                                                onClick={(e) => {
                                                  handleClickPickAnotherLager(
                                                    e.target.innerText
                                                  );
                                                }}
                                                size="small"
                                                style={
                                                  rowValue.value ===
                                                    rows[0].value &&
                                                  rowValue.value ===
                                                    pairValueColor
                                                    ? {
                                                        fontSize: "11px",
                                                        fontWeight: "bold",
                                                        textTransform: "none",
                                                        color: "#ffff",
                                                      }
                                                    : rowValue.value !==
                                                        rows[0].value &&
                                                      rowValue.value ===
                                                        pairValueColor
                                                    ? {
                                                        color: "#ffff",
                                                        fontSize: "11px",
                                                        fontWeight: "bold",
                                                        textTransform: "none",
                                                      }
                                                    : rowValue.value ===
                                                        rows[0].value &&
                                                      rowValue.value !==
                                                        pairValueColor
                                                    ? {
                                                        color: "black",
                                                        fontSize: "11px",
                                                        fontWeight: "bold",
                                                        textTransform: "none",
                                                      }
                                                    : {
                                                        fontSize: "11px",
                                                        textTransform: "none",
                                                        color: "black",
                                                      }
                                                }
                                              >
                                                {rowValue.value}
                                              </Button>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    })}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  }
                }
                if (row.name.includes("Radsatz")) {
                  if (row.name.includes("Haupt")) {
                    return (
                      <>
                        <TableRow
                          key={row.name}
                          style={openLagers ? { display: "none" } : null}
                        >
                          <IconButton
                            aria-label="expand row"
                            size="medium"
                            style={{ marginTop: "0.5em" }}
                            onClick={() => setOpenRadsatzs(!openRadsatzs)}
                          >
                            {openRadsatzs ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ width: "30rem" }}
                          >
                            {t(row.name.replace("Haupt", ""))}
                          </TableCell>
                          <TableCell style={{ width: 160 }} align="right">
                            {row.value}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          style={openLagers ? { display: "none" } : null}
                        >
                          <TableCell
                            style={{
                              paddingBottom: 0,
                              paddingTop: 0,
                              border: "none",
                            }}
                            colSpan={6}
                          >
                            <Collapse
                              in={openRadsatzs}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box sx={{ margin: 0, align: "right" }}>
                                <Table size="small" aria-label="purchases">
                                  <TableBody>
                                    {rows.map((rowValue, index) => {
                                      if (rowValue.name.includes("RadsatzID")) {
                                        return (
                                          <RadsatzRow
                                            rows={rows}
                                            rowValue={rowValue}
                                            openLagers={openLagers}
                                            radsatzRowValue={rowValue.name}
                                            handleClickPickAnotherLager={
                                              handleClickPickAnotherLager
                                            }
                                            pairValueColor={pairValueColor}
                                            handleOpenMontage={() =>
                                              handleOpenMontage(index)
                                            }
                                          />
                                        );
                                      }
                                    })}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <TableRow key={row.name}></TableRow>
                      </>
                    );
                  }
                }
                if (row.name.includes("Drehgestell")) {
                  if (row.name.includes("Haupt")) {
                    return (
                      <>
                        <TableRow
                          key={row.name}
                          style={
                            openLagers || openRadsatzs
                              ? { display: "none" }
                              : null
                          }
                        >
                          <IconButton
                            aria-label="expand row"
                            size="medium"
                            style={{ marginTop: "0.5em" }}
                            onClick={() =>
                              setOpenDrehgestells(!openDrehgestells)
                            }
                          >
                            {openDrehgestells ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                          <TableCell component="th" scope="row">
                            {t(row.name.replace("Haupt", ""))}
                          </TableCell>
                          <TableCell style={{ width: 160 }} align="right">
                            {row.value}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          style={
                            openLagers || openRadsatzs
                              ? { display: "none" }
                              : null
                          }
                        >
                          <TableCell
                            style={{
                              paddingBottom: 0,
                              paddingTop: 0,
                              border: "none",
                            }}
                            colSpan={6}
                          >
                            <Collapse
                              in={openDrehgestells}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box sx={{ margin: 1, align: "right" }}>
                                <Table size="small" aria-label="purchases">
                                  <TableBody>
                                    {rows.map((rowValue) => {
                                      if (
                                        rowValue.name.includes("DrehgestellID")
                                      ) {
                                        return (
                                          <DrehgestellRow
                                            rows={rows}
                                            rowValue={rowValue}
                                            openLagers={openLagers}
                                            drehgestellRowValue={rowValue.name}
                                            openRadsatzs={openRadsatzs}
                                          />
                                        );
                                      }
                                    })}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  }
                }
                if (row.name.includes("Wagon")) {
                  if (
                    row.name.includes("Haupt") &&
                    !openRadsatzs /* &&
                    !openLagers &&
                    !openRadsatzs &&
                    !openDrehgestells */
                  ) {
                    return (
                      <>
                        <TableRow
                          key={row.name}
                          /*  style={
                            openLagers || openRadsatzs || openDrehgestells
                              ? { display: "none" }
                              : null
                          } */
                        >
                          <IconButton></IconButton>
                          <TableCell component="th" scope="row">
                            {t(row.name.replace("Haupt", ""))}
                          </TableCell>
                          <TableCell style={{ width: 160 }} align="right">
                            {row.value}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <TableRow key={row.name}></TableRow>
                      </>
                    );
                  }
                }
              })}
            </TableBody>
            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </Item>
  );
}
