import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
import commonDe from "./locales/de/translation.json";
import commonEn from "./locales/en/translation.json";
import commonFr from "./locales/fr/translation.json";

const languages = ["en", "de", "fr"];
const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};
const resources = {
  de: { common: commonDe },
  en: { common: commonEn },
  fr: { common: commonFr },
};
i18n
  /*
     load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
     learn more: https://github.com/i18next/i18next-http-backend
    */
  .use(Backend)
  /*
     detect user language
     learn more: https://github.com/i18next/i18next-browser-languageDetector
    */
  .use(LanguageDetector)
  /*
     pass the i18n instance to react-i18next.
    */
  .use(initReactI18next)
  /*
     init i18next
     for all options read: https://www.i18next.com/overview/configuration-options
    */
  .init({
    debug: true,
    //lng: "de",
    resources,
    detection: options,
    ns: ["common"],
    defaultNS: "common",
    fallbackLng: "en",
    supportedLngs: ["de", "en", "fr"],

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
  });
