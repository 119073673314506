import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useTranslation, i18n } from "react-i18next";

import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  montageDataRows0,
  montageDataRows1,
  montageDataRows2,
  montageDataRows3,
} from "../DummyData";
import {
  styled,
  Button,
  TableCell,
  tableCellClasses,
  Box,
  TableRow,
  Paper,
  TableContainer,
  IconButton,
  Collapse,
  Table,
  TableBody,
  TableHead,
} from "@mui/material";

const ColorButtonCancel = styled(Button)(() => ({
  color: "white",
  fontWeight: "600",
  backgroundColor: "#0F2A47",
  padding: "0.7rem",

  "&:hover": {
    backgroundColor: "#C7372F",
    color: "#ffff",
  },
}));

const useStyles = makeStyles(() => ({
  diaglogContent: {
    width: "50rem",
    height: "fit-content",
  },
  rootContent: {
    overflowY: "hidden !important",
  },
  diaglogAction: {
    padding: "1rem 1.5rem 1rem !important",
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  let date = "";
  /* if (row.datum !== "") {
    var parts = row.datum.split("-");
    date = new Date(parts[0], parts[1] - 1, parts[2]).toLocaleDateString();
  }
  let fettChargeID = "";
  if (row.fettCharge !== undefined) {
    fettChargeID = row.fettCharge.replace("urn:epc:class:lgtin:", "");
  } */
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell component="th" scope="row" align="left">
          {row.radsatzmontageDatum}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row.montageort}
        </StyledTableCell>
        <StyledTableCell align="right">
          <IconButton color="default" onClick={() => setOpen(!open)}>
            <MoreHorizIcon />
          </IconButton>
        </StyledTableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("RadsatzwellenNr")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.radsatzwellenNr}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("Radscheibelinks")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.radscheibeLinks}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("AufpressdruckRadscheibelinks")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.aufpressdruckRadscheibeLinks}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("Radscheiberechts")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.radscheibeRechts}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("AufpressdruckRadscheiberechts")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.aufpressdruckRadscheibeRechts}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("Bremsscheiberechts")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.bremsscheibeRechts}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("AufpressdruckBremsscheiberechts")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.aufpressdruckBremsscheibeRechts}
                    </TableCell>
                  </StyledTableRow>

                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("Bremsscheibelinks")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.bremsscheibeLinks}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("AufpressdruckBremsscheibelinks")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.aufpressdruckBremsscheibeLinks}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("Lagerlinks")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.lagerLinks}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("AufpressdruckLagerlinks")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.aufpressdruckLagerLinks}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("Lagerrechts")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.lagerRechts}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("AufpressdruckLagerrechts")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.aufpressdruckLagerRechts}
                    </TableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const MontageDataDiaglog = (props) => {
  const { openMontage, handleCloseMontage, radsatzIndex } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Dialog
        open={openMontage}
        onClose={handleCloseMontage}
        aria-labelledby="draggable-dialog-title"
        xs={{
          padding: "1rem !important",
        }}
      >
        <DialogTitle
          style={{
            cursor: "move",
            fontWeight: "bold",
            fontSize: "150%",
          }}
          id="draggable-dialog-title"
        >
          {t("Montage-Daten")}
        </DialogTitle>
        <DialogContent className={classes.rootContent}>
          <Box className={classes.diaglogContent}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: "70vh" }}>
                <Table
                  sx={{ minWidth: "auto" }}
                  aria-label="sticky table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">
                        {t("Radsatzmontage")}
                      </StyledTableCell>
                      <StyledTableCell>{t("Montageort")}</StyledTableCell>

                      <StyledTableCell align="right">
                        {t("Details")}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {radsatzIndex === 10 ? (
                    <TableBody>
                      {montageDataRows0.map((row) => {
                        return <Row key={row.id} row={row} />;
                      })}
                    </TableBody>
                  ) : radsatzIndex === 11 ? (
                    <TableBody>
                      {montageDataRows1.map((row) => {
                        return <Row key={row.id} row={row} />;
                      })}
                    </TableBody>
                  ) : radsatzIndex === 12 ? (
                    <TableBody>
                      {montageDataRows2.map((row) => {
                        return <Row key={row.id} row={row} />;
                      })}
                    </TableBody>
                  ) : (
                    <TableBody>
                      {montageDataRows3.map((row) => {
                        return <Row key={row.id} row={row} />;
                      })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </DialogContent>

        <DialogActions className={classes.diaglogAction}>
          <ColorButtonCancel autoFocus onClick={handleCloseMontage}>
            {t("Abschließen")}
          </ColorButtonCancel>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MontageDataDiaglog;
