import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useTranslation } from "react-i18next";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import {
  styled,
  Button,
  TableCell,
  tableCellClasses,
  Box,
  TableRow,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
} from "@mui/material";

const ColorButtonCancel = styled(Button)(() => ({
  color: "white",
  fontWeight: "600",
  backgroundColor: "#0F2A47",
  padding: "0.7rem",

  "&:hover": {
    backgroundColor: "#C7372F",
    color: "#ffff",
  },
}));

const useStyles = makeStyles(() => ({
  diaglogContent: {
    width: "fit-content",
    height: "fit-content",
  },
  rootContent: {
    overflowY: "hidden !important",
    padding: "10px 10px !important",
  },
  diaglogAction: {
    padding: "1rem 1.5rem 1rem !important",
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const HistorikDataDiaglog = (props) => {
  const { openNachhaltigkeit, handleCloseNachhaltigkeit, rows } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        open={openNachhaltigkeit}
        onClose={handleCloseNachhaltigkeit}
        aria-labelledby="draggable-dialog-title"
        xs={{
          padding: "1rem !important",
        }}
      >
        <DialogTitle
          style={{
            cursor: "move",
            fontWeight: "bold",
            fontSize: "100%",
          }}
          id="draggable-dialog-title"
        >
          {t("Nachhaltigkeitsdaten")}
        </DialogTitle>
        <DialogContent className={classes.rootContent}>
          <Box className={classes.diaglogContent}>
            <Paper sx={{ width: "90%", overflow: "hidden" }}>
              <TableContainer component={Paper}>
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left" colSpan={3}>
                        {t(
                          "Einsparung durch die Wiederaufbereitung (bezogen auf die Neufertigung)"
                        )}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.title}>
                        <StyledTableCell>{t(row.title)}</StyledTableCell>
                        <StyledTableCell align="right">
                          {row.value1}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.value2}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </DialogContent>

        <DialogActions className={classes.diaglogAction}>
          <ColorButtonCancel autoFocus onClick={handleCloseNachhaltigkeit}>
            {t("Abschließen")}
          </ColorButtonCancel>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HistorikDataDiaglog;
