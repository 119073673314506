import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
export const Item = styled(Paper)(({ theme }) => ({
  /*   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
   */ ...theme.typography.body2,
  padding: 0,
  textAlign: "center",
  // color: theme.palette.text.secondary,
  minHeight: "80vh",
  border: "8px solid " + theme.palette.borderColor,
  height: "fit-content",
  /* [theme.breakpoints.down("sm")]: {
    width: "100%",
  }, */
  // marginBottom: "1vh",
}));
