import { makeStyles } from "@mui/styles";
import * as React from "react";

import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTranslation, i18n } from "react-i18next";

import {
  styled,
  Button,
  TableCell,
  tableCellClasses,
  Box,
  TableRow,
  Paper,
  TableContainer,
  IconButton,
  Collapse,
  Table,
  TableBody,
  TableHead,
} from "@mui/material";
import { display } from "@mui/system";

const ColorButtonCancel = styled(Button)(() => ({
  color: "white",
  fontWeight: "600",
  backgroundColor: "#0F2A47",
  padding: "0.7rem",

  "&:hover": {
    backgroundColor: "#C7372F",
    color: "#ffff",
  },
}));

const useStyles = makeStyles(() => ({
  diaglogContent: {
    width: "35rem",
    height: "fit-content",
  },
  rootContent: {
    overflowY: "hidden !important",
  },
  diaglogAction: {
    padding: "1rem 1.5rem 1rem !important",
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Row = (props) => {
  const { t, i18n } = useTranslation();

  const { row } = props;
  const [open, setOpen] = useState(false);
  let date = "";
  if (row.datum !== "") {
    var parts = row.datum.split("-");
    date = new Date(parts[0], parts[1] - 1, parts[2]).toLocaleDateString();
  }
  let fettChargeID = "";
  if (row.fettCharge !== undefined) {
    fettChargeID = row.fettCharge.replace("urn:epc:class:lgtin:", "");
  }

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell component="th" scope="row" align="left">
          {date}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {t(row.status)}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="left">
          {row.betriebskilometer}
        </StyledTableCell>
        <StyledTableCell align="right">
          <IconButton color="default" onClick={() => setOpen(!open)}>
            <MoreHorizIcon />
          </IconButton>
        </StyledTableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("Fehlercode")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.fehlerCode !== null ? t(row.fehlerCode) : "-------"}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("Revisionsauftrag")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.revisionsauftrag}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("Revisionswerk")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {"Schaeffler Werk 200"}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("Lagerhersteller")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.lagerhesteller}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("Produktionsland")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.produktionsland}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("Axialspiel")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.axialspiel}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("FettCharge")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {fettChargeID}
                    </TableCell>
                  </StyledTableRow>

                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("FettDatum")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.fettDatum}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {t("FettGewicht")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.fettGewicht}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow
                    key={row.id}
                    style={
                      row.fehlerCode !== null
                        ? { display: "table-row" }
                        : { display: "none" }
                    }
                  >
                    <TableCell component="th" scope="row">
                      <img
                        height={200}
                        width={200}
                        alt="fehler"
                        src="./lagerSchrott.png"
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {t(row.fehlerCode)}
                    </TableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const HistorikDataDiaglog = (props) => {
  const { openHistorik, handleCloseHistorik, rows } = props;
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Dialog
        open={openHistorik}
        onClose={handleCloseHistorik}
        aria-labelledby="draggable-dialog-title"
        xs={{
          padding: "1rem !important",
        }}
      >
        <DialogTitle
          style={{
            cursor: "move",
            fontWeight: "bold",
            fontSize: "150%",
          }}
          id="draggable-dialog-title"
        >
          {t("HistorikDaten")}
        </DialogTitle>
        <DialogContent className={classes.rootContent}>
          <Box className={classes.diaglogContent}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: "70vh" }}>
                <Table
                  sx={{ minWidth: "auto" }}
                  aria-label="sticky table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">
                        {t("Revision")}
                      </StyledTableCell>
                      <StyledTableCell>
                        {t("Inspecting Status")}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {t("Betriebskilometer")}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {t("Details")}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => {
                      return <Row key={row.id} row={row} />;
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </DialogContent>

        <DialogActions className={classes.diaglogAction}>
          <ColorButtonCancel autoFocus onClick={handleCloseHistorik}>
            {t("Abschließen")}
          </ColorButtonCancel>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HistorikDataDiaglog;
