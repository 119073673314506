import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useTranslation } from "react-i18next";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, TextField } from "@mui/material";
import { styled, Button } from "@mui/material";

const ColorButtonCancel = styled(Button)(() => ({
  color: "white",
  fontWeight: "600",
  backgroundColor: "#0F2A47",
  padding: "0.7rem",
  fontSize: "0.7rem",
  "&:hover": {
    backgroundColor: "#C7372F",
    color: "#ffff",
  },
}));
const ColorButtonConfirm = styled(Button)(() => ({
  color: "white",
  padding: "0.7rem",
  fontWeight: "600",
  fontSize: "0.7rem",
  backgroundColor: "#0F2A47",
  "&:hover": {
    backgroundColor: "#0E86D4",
    color: "#0F2A47",
  },
}));

const useStyles = makeStyles(() => ({
  diaglogAction: {
    padding: "1rem 1.5rem 1rem !important",
  },
}));
const LoginDialog = (props) => {
  const { open, handleClose, handleLogin, handleLogout, logged } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        xs={{
          padding: "1rem !important",
        }}
      >
        <DialogTitle
          style={{
            cursor: "move",
            fontWeight: "bold",
            fontSize: "100%",
            marginRight: "auto",
          }}
          id="draggable-dialog-title"
        >
          {t("Als Kunde einloggen")}
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              required
              id="outlined-required"
              label={t("Username")}
              defaultValue=""
            />
            <TextField
              required
              id="outlined-required"
              label={t("Passwort")}
              defaultValue=""
            />
          </Box>
        </DialogContent>

        <DialogActions className={classes.diaglogAction}>
          {logged ? (
            <>
              <ColorButtonCancel autoFocus onClick={handleClose()}>
                {t("Abschließen")}
              </ColorButtonCancel>
              <ColorButtonCancel autoFocus onClick={handleLogout()}>
                {t("Ausloggen")}
              </ColorButtonCancel>
            </>
          ) : (
            <>
              <ColorButtonCancel autoFocus onClick={handleClose()}>
                {t("Abschließen")}
              </ColorButtonCancel>
              <ColorButtonConfirm autoFocus onClick={handleLogin()}>
                {t("Einlogen")}
              </ColorButtonConfirm>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LoginDialog;
