/* eslint-disable react/jsx-no-comment-textnodes */
import { React, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Item } from "./Item";
import CircularProgress from "@mui/material/CircularProgress";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionIcon from "@mui/icons-material/Description";

import {
  Box,
  TableContainer,
  TableCell,
  TableRow,
  IconButton,
  TableFooter,
  Collapse,
  Table,
  TableBody,
} from "@mui/material";

import { pdfjs } from "react-pdf";
import { useTranslation } from "react-i18next";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function SchaefflerComponent(props) {
  const {
    classes,
    rows,
    progress,
    pairValueColor,
    handleOpenPDF,
    handleOpenPDFWerkzeug,
    handleOpenPDFMontage,
    handleOpenHistorik,
    handleOpenNachhaltigkeit,
    normalLogin,
    serial_number,
  } = props;
  const { t } = useTranslation();
  const [openLagerID, setOpenLagerID] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);

  return (
    <Item style={{ height: "fit-content" }}>
      <Box className={classes.boxIconDatabase}>
        <img
          alt="schaefflerIcon"
          src="./schaefflerIcon.jpg"
          style={{ height: "25px", width: "250px", marginTop: "10px" }}
        ></img>
        {/* <form style={{ marginTop: "10px" }}></form> */}
        <Paper className={classes.titleInfo}>
          {t("Digitaler Produktepass")}
        </Paper>
        <Paper className={classes.titleInfo2}>
          {t("gemäss EU COM(2022) 140 für Schaeffler Radsatzlager")}
        </Paper>
        <CircularProgress
          color="success"
          sx={
            progress ? { display: "flex", margin: "auto" } : { display: "none" }
          }
        />
      </Box>

      <Box className={classes.boxTable}>
        <img
          alt="datenbank"
          src="./Tarol-001.jpg"
          className={classes.photoRadsatz}
        ></img>
        <TableContainer component={Paper}>
          <Table
            sx={{
              maxWidth: "90vw",
            }}
            aria-label="custom pagination table"
          >
            <TableBody>
              {rows
                .filter(row => ["GTIN"].includes(row.name))
                .map((row) => {
                  if (row.name === "GTIN") {
                    if (row.value !== undefined && row.value !== null) {
                      return (
                        <>
                          <TableRow
                            key={row.name}
                            onClick={() => setOpenLagerID(!openLagerID)}
                          >
                            <TableCell component="th" scope="row">
                              {t(row.name)}
                            </TableCell>
                            <TableCell align="right">
                              {" "}
                              <IconButton color="default">
                                <MoreHorizIcon />
                              </IconButton>{" "}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{
                                paddingBottom: 0,
                                paddingTop: 0,
                                border: "none",
                              }}
                              colSpan={6}
                            >
                              <Collapse
                                in={openLagerID}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box
                                  sx={{
                                    margin: 1,
                                    align: "right",
                                    border: "none",
                                  }}
                                >
                                  <Table size="small" aria-label="purchases">
                                    <TableBody>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            lineHeight: "1",
                                            padding: "1px 0px 1px 1vw",
                                            border: "none",
                                            fontSize: "5px !important",
                                          }}
                                        >
                                          GTIN:
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            lineHeight: "1",
                                            padding: "1px 0px 1px 2vw",
                                            border: "none",
                                            fontSize: "5px !important",
                                          }}
                                        >
                                          {row.value}
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell
                                          style={{
                                            lineHeight: "1",
                                            padding: "1px 0px 1px 1vw",
                                            border: "none",
                                            fontSize: "5px !important",
                                          }}
                                        >
                                          {t("Serialnummer auf Lager:")}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            lineHeight: "1",
                                            padding: "1px 0px 1px 2vw",
                                            border: "none",
                                            fontSize: "5px !important",
                                          }}
                                        >
                                          {serial_number}
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    }
                  }
                })
              }
              <TableRow
                key="Details"
                onClick={() => setOpenDetails(!openDetails)}
              >
                <TableCell component="th" scope="row">
                  {t("Technische Details")}
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <IconButton color="default">
                    <MoreHorizIcon />
                  </IconButton>{" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    paddingBottom: 0,
                    paddingTop: 0,
                    border: "none",
                  }}
                  colSpan={6}
                >
                  <Collapse
                    in={openDetails}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box
                      sx={{
                        margin: 1,
                        align: "right",
                        border: "none",
                      }}
                    >
                      <Table size="small" aria-label="purchases">
                        <TableBody>
                          {rows.filter(row => !["GTIN", "HistorikDaten", "Nachhaltigkeitsdaten", "Zeugnis", "Montage", "Werkzeug"].includes(row.name)).map((row) => {
                            return (
                              <TableRow key={row.name}>
                                <TableCell
                                  style={
                                    row.value === pairValueColor && pairValueColor !== ""
                                      ? { background: "#003060", color: "#ffff" }
                                      : {}
                                  }
                                  component="th"
                                  scope="row"
                                >
                                  {t(row.name)}
                                </TableCell>
                                <TableCell
                                  style={
                                    row.value === pairValueColor && pairValueColor !== ""
                                      ? {
                                        width: 160,
                                        background: "#003060",
                                        color: "#ffff",
                                      }
                                      : { width: 160 }
                                  }
                                  align="right"
                                >
                                  {row.value}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
              {rows
                .filter(row => ["HistorikDaten", "Nachhaltigkeitsdaten"].includes(row.name))
                .map((row) => {
                  if (row.name === "HistorikDaten") {
                    if (normalLogin) {
                      return (
                        <TableRow key={row.name} onClick={handleOpenHistorik}>
                          <TableCell
                            style={
                              row.value === pairValueColor &&
                                pairValueColor !== ""
                                ? { background: "#003060", color: "#ffff" }
                                : {}
                            }
                            component="th"
                            scope="row"
                          >
                            {t(row.name)}
                          </TableCell>
                          <TableCell
                            style={
                              row.value === pairValueColor &&
                                pairValueColor !== ""
                                ? {
                                  width: 160,
                                  background: "#003060",
                                  color: "#ffff",
                                }
                                : { width: 160 }
                            }
                            align="right"
                          >
                            <IconButton
                              color="default"
                              onClick={handleOpenHistorik}
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    } else {
                      return null;
                    }
                  } else if (row.name === "Nachhaltigkeitsdaten") {
                    if (normalLogin) {
                      return (
                        <TableRow
                          key={row.name}
                          onClick={handleOpenNachhaltigkeit}
                        >
                          <TableCell
                            style={
                              row.value === pairValueColor &&
                                pairValueColor !== ""
                                ? { background: "#003060", color: "#ffff" }
                                : {}
                            }
                            component="th"
                            scope="row"
                          >
                            {t(row.name)}
                          </TableCell>
                          <TableCell
                            style={
                              row.value === pairValueColor &&
                                pairValueColor !== ""
                                ? {
                                  width: 160,
                                  background: "#003060",
                                  color: "#ffff",
                                }
                                : { width: 160 }
                            }
                            align="right"
                          >
                            <IconButton
                              color="default"
                              onClick={handleOpenNachhaltigkeit}
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    } else {
                      return null;
                    }
                  }
                })
              }
              <TableRow
                key="Dokumente"
              >
                <TableCell component="th" scope="row">
                  {t("Dokumente")}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Grid container direction="row" alignItems="center" spacing={0}>
                    {rows
                      .filter(row => ["Zeugnis", "Montage", "Werkzeug"].includes(row.name))
                      .map((row) => {
                        if (row.name === "Zeugnis") {
                          if (normalLogin) {
                            return (
                              <Grid item>
                                <IconButton
                                  color="default"
                                  onClick={handleOpenPDF}
                                >
                                  <DescriptionIcon />
                                </IconButton>
                                {t(row.name)}
                              </Grid>
                            );
                          } else {
                            return null;
                          }
                        } else if (row.name === "Montage") {
                          if (normalLogin) {
                            return (
                              <Grid item>
                                <IconButton
                                  color="default"
                                  onClick={handleOpenPDFMontage}
                                >
                                  <DescriptionIcon />
                                </IconButton>
                                {t(row.name)}
                              </Grid>
                            );
                          } else {
                            return null;
                          }
                        } else if (row.name === "Werkzeug") {
                          if (normalLogin) {
                            return (
                              <Grid item>
                                <IconButton
                                  color="default"
                                  onClick={handleOpenPDFWerkzeug}
                                >
                                  <DescriptionIcon />
                                </IconButton>
                                {t(row.name)}
                              </Grid>
                            );
                          } else {
                            return null;
                          }
                        }
                      })
                    }
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </Item>
  );
}
