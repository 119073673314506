import { Grid } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

/**
 * Best-Practice wenn man mit Darkmode und Grids arbeiten möchte und das bevorzugute Styling von Material-UI nutzt.
 */
const useStyles = makeStyles((theme) => ({
  footer: {
    position: "sticky",
    color: "theme.palette.text.primary",
    borderTop: ".5vh solid " + theme.palette.borderColor,
    bottom: 0,
    marginBottom: 0,
    [theme.breakpoints.up("xs")]: {
      /*  padding: "1.5vh 2vw 1.5vh 2vw", */
    },
    [theme.breakpoints.down("xs")]: {
      /*   padding: "0.5rem 1rem", */
    },
    backgroundColor: theme.palette.background.footer,
    /* fontSize: "2vh", */
    width: "100%",
    height: "1.7vh",
    fontFamily: "Montserrat-Light",
  },
  /* footerIcon: {
    width: "8.5vw",
    height: "3.5vh",
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      width: "150px",
      height: "21px",
    },
  },
  footerIconMS: {
    width: "3.5vh",
    height: "3.5vh",
    marginRight: "2vw",
    float: "left",
  }, */
  appName: {
    paddingTop: "0.3vh",
    float: "left",
  },
  logoGridItem: {
    display: "flex",
    flexDirection: "row-reverse",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  copyrightGridItem: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      fontSize: "60%",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        alignContent="center"
        direction="row"
        wrap="wrap-reverse"
      >
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          className={classes.copyrightGridItem}
        >
          <div>
            {/*  <img
              src={fxFooterLogoMS}
              alt="LogoFx"
              className={classes.footerIconMS}
            /> */}
          </div>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          className={classes.logoGridItem}
        >
          {/* <img
            src={fxFooterLogoWhite}
            alt="Logo"
            className={classes.footerIcon}
          /> */}
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
