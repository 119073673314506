import "./App.css";
import axios from "axios";
import { Box, Snackbar, Alert, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import SchaefflerComponent from "./Components_Mobile/SchaefflerComponent";
import Footer from "./Components_Mobile/Footer";
import { makeStyles } from "@mui/styles";
import Header from "./Components_Mobile/Header";
import { checkTypeOfID, delay } from "./utils";
import PDFDialog from "./Components_Mobile/PDFDialog";
import PDFDialogMontage from "./Components_Mobile/PDFDialogMontage";
import PDFDialogWerkzeug from "./Components_Mobile/PDFDialogWerkzeug";
import HistorikDataDialog from "./Components_Mobile/HistorikDataDialog";
import NachhaltigkeitDialog from "./Components_Mobile/NachhaltigkeitDialog";
import RadsatzMontageDialog from "./Components_Mobile/RadsatzMontageDialog";
import { useParams } from "react-router-dom";
import NormalLoginDialog from "./Components_Mobile/NormalLoginDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "100vh",
    width: "100vw",
    alignItems: "center",
  },
  appbar: {
    textAlign: "center",
    //position: "absolut",
    height: "5vh",
  },

  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      width: "100vw",
      minHeight: "82.95vh",
      height: "fit-content",
      padding: "0.1rem 1rem 0 1rem",
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "100vw",
      minHeight: "calc(100% - 165px)",
      height: "fit-content",

      padding: "0.1rem 1rem 0 1rem",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "100vw",
      minHeight: "84.4vh",
      height: "fit-content",

      padding: "0.1rem 1rem 0 1rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      minHeight: "84.4vh",
      height: "fit-content",

      padding: "0.1rem 1rem 0 1rem",
    },
  },
  photoRadsatz: {
    [theme.breakpoints.down("sm")]: {
      height: "20vh",
      width: "50vw",
    },
    [theme.breakpoints.up("sm")]: {
      height: "20vh",
      width: "20vw",
      maxWidth: "max-content",
    },
  },
  photoDatabase: {
    [theme.breakpoints.down("md")]: {
      marginTop: "1em",
      height: "5em",
      width: "5em",
    },
    [theme.breakpoints.between("md", "lg")]: {
      marginTop: "1em",
      height: "7em",
      width: "7em",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "1em",
      height: "8em",
      width: "8em",
    },
  },
  titleInfo: {
    fontSize: "200%",
    boxShadow: "none !important",
    margin: "10px",
    color: theme.palette.text.option2,
  },
  titleInfo2: {
    fontSize: "90%",
    boxShadow: "none !important",
    margin: "5px",
    color: theme.palette.text.option2,
  },
  boxTable: {
    margin: "10px",
    // borderTop: "8px solid " + theme.palette.borderColor,
  },
  boxIconDatabase: {
    marginTop: "8px",
    borderBottom: "2px solid " + theme.palette.borderColor,
  },
  pdf: { height: "10vh" },
}));

const App = () => {
  let { gtin, serial_number } = useParams();

  const [pairValueColor, setPairValueColor] = useState("");
  /*  const [inputID, setInputID] =
    useState(""); */
  const [filePDF, setFilePDF] = useState("");
  const [filePathPDFWerkzeug, setFilePathPDFWerkzeug] = useState("");
  const [filePathPDFMontage, setFilePathPDFMontage] = useState("");


  const [inputID, setInputID] = useState(
    "urn:epc:id:sgtin:" +
      gtin.slice(1, 8) +
      ".0" +
      gtin.slice(8, 13) +
      "." +
      serial_number
  );
  const [lagerInfo, setLagerInfo] = useState([
    { name: "GTIN", value: inputID.replace("urn:epc:id:sgtin:", "") },
    { name: "Lagertyp", value: "Tarol 150x250" },
    { name: "Zeichnungsnummer", value: "F-569608.TAROL150/250-B-TVP-M32AX" },
    { name: "Schaeffler Material Nr.", value: "063504391-0000" },
    { name: "Hersteller", value: "Schaeffler (FAG)" },
    { name: "Produktionsdatum", value: "" },
    { name: "Herstellwerk", value: "" },
    { name: "Gewicht", value: "45kg" },
    { name: "Abmessungen", value: "150x250x227.25" },
    { name: "Fettlablaufdatum", value: "03/2017" },
    { name: "Revisionszyklus", value: "2" },
    { name: "Revisionsstatus", value: "OK" },
    { name: "Zeugnis", value: "" },
    { name: "Montage", value: "" },
    { name: "Werkzeug", value: "" },
    { name: "HistorikDaten", value: "" },
    { name: "Nachhaltigkeitsdaten", value: "" },
  ]);

  const [konfiguration, setKonfiguration] = useState([
    { name: "HauptLager-ID", value: "" },
    { name: "LagerID1", value: "" },
    { name: "LagerID2", value: "" },
    { name: "LagerID3", value: "" },
    { name: "LagerID4", value: "" },
    { name: "LagerID5", value: "" },
    { name: "LagerID6", value: "" },
    { name: "LagerID7", value: "" },
    { name: "LagerID8", value: "" },
    { name: "HauptRadsatz", value: "" },
    { name: "RadsatzID1", value: "" },
    { name: "RadsatzID2", value: "" },
    { name: "RadsatzID3", value: "" },
    { name: "RadsatzID4", value: "" },
    { name: "HauptDrehgestell", value: "" },
    { name: "DrehgestellID1", value: "" },
    { name: "DrehgestellID2", value: "" },
    { name: "HauptWagonNummer", value: "" },
  ]);

  const classes = useStyles();

  const [errorInput, setErrorInput] = useState(false);

  const [progressSchaeffler, setProgressSchaeffler] = useState(false);

  const [openPDF, setOpenPDF] = useState(false);
  const [openPDFWerkzeug, setOpenPDFWerkzeug] = useState(false);
  const [openPDFMontage, setOpenPDFMontage] = useState(false);
  const [openHistorik, setOpenHistorik] = useState(false);
  const [openNachhaltigkeit, setOpenNachhaltigkeit] = useState(false);

  const [openMontage, setOpenMontage] = useState(false);
  const [nachhaltigkeitRows, setNachhaltigkeitRows] = useState([
    {
      title: "CO2 Äquivalente",
      value1: "221 kg",
      value2: "Ca. 97%",
    },
    {
      title: "Elektr.Energie",
      value1: "1051 kWh",
      value2: "Ca. 97%",
    },
    {
      title: "Wasser",
      value1: "1990 l",
      value2: "Ca. 98%",
    },
  ]);
  const [historikDataRows, setHistorikDataRows] = useState([
    {
      datum: "2010-03-01",
      status: "Lager-Schrott",
      revisionswerk: "-----",
      betriebskilometer: "1950000",
      fehlerCode: "Stromdurchgang",
      revisionsauftrag: "Demo Lager",
      lagerhesteller: "Demo Hersteller",
      produktionsland: "Demo Land",
      axialspiel: "-----",
      fettCharge: "-----",
      fettDatum: "-----",
      fettGewicht: "-----",
    },
  ]);

  const handleCloseMontage = () => {
    setOpenMontage(false);
  };
  const handleOpenMontage = (radsatzIndex) => {
    console.log("radsatzIndex: " + radsatzIndex);
    setOpenMontage(true);
  };
  const handleOpenHistorik = () => {
    setOpenHistorik(true);
  };
  const handleOpenNachhaltigkeit = () => {
    setOpenNachhaltigkeit(true);
  };
  const handleCloseNachhaltigkeit = () => {
    setOpenNachhaltigkeit(false);
  };
  const handleCloseHistorik = () => {
    setOpenHistorik(false);
  };

  const resetKonfigurationInfo = () => {
    setKonfiguration([
      { name: "HauptLager-ID", value: "" },
      {
        name: "LagerID1",
        value: "",
      },
      {
        name: "LagerID2",
        value: "",
      },
      {
        name: "LagerID3",
        value: "",
      },
      {
        name: "LagerID4",
        value: "",
      },
      {
        name: "LagerID5",
        value: "",
      },
      {
        name: "LagerID6",
        value: "",
      },
      {
        name: "LagerID7",
        value: "",
      },
      {
        name: "LagerID8",
        value: "",
      },
      { name: "HauptRadsatz", value: "" },
      { name: "RadsatzID1", value: "", subValue1: "", subValue2: "" },
      { name: "RadsatzID2", value: "", subValue1: "", subValue2: "" },
      { name: "RadsatzID3", value: "", subValue1: "", subValue2: "" },
      { name: "RadsatzID4", value: "", subValue1: "", subValue2: "" },
      { name: "HauptDrehgestell", value: "" },
      {
        name: "DrehgestellID1",
        value: "",
        subValue1: "",
        subValue2: "",
      },
      {
        name: "DrehgestellID2",
        value: "",
        subValue1: "",
        subValue2: "",
      },
      { name: "HauptWagonNummer", value: "" },
    ]);
  };

  const saveLagerInfoDataObject = (data) => {
    var parts = data.produktionsdatum.split("-");
    let date = new Date(parts[0], parts[1] - 1, parts[2]);

    /*  let dataObject = [
      {
        name: "Lager-ID",
        value: inputID
          ? inputID
          : inputIDInGarantieFall,
      },
      { name: "Produktionsdatum", value: date.toLocaleDateString() },
      { name: "Produktionswerk", value: data.produktionsWerk },
      { name: "OrderNummer", value: data.orderNumber },
    ]; */

    setLagerInfo(
      lagerInfo.map((lager) =>
        lager.name === "GTIN"
          ? {
              ...lager,
              name: "GTIN",
              value: inputID
                ? inputID.replace("urn:epc:id:sgtin:", "")
                : data.lagerGIAI.replace("urn:epc:id:sgtin:", ""),
            }
          : lager.name === "Produktionsdatum"
          ? {
              ...lager,
              name: "Produktionsdatum",
              value: date.toLocaleDateString(),
            }
          : lager.name === "Herstellwerk"
          ? { ...lager, name: "Herstellwerk", value: data.produktionsWerk }
          : lager.name === "OrderNummer"
          ? { ...lager, name: "OrderNummer", value: data.orderNumber }
          : lager.name === "Zeugnis"
          ? { ...lager, name: "Zeugnis", value: data.pdfName }
          : lager.name === "Werkzeug"
          ? { ...lager, name: "Werkzeug", value: "" }
          : lager.name === "Montage"
          ? { ...lager, name: "Montage", value: "" }
          : lager.name === "HistorikDaten"
          ? {
              ...lager,
              name: "HistorikDaten",
              value: data.historikDaten.revisionArrayList,
            }
          : { ...lager, name: lager.name, value: lager.value }
      )
    );
  };
  const saveHistorikDataObject = (data) => {
    setHistorikDataRows(data);
  };
  const changeToGermanDateTime = (string) => {
    let returnDateTime =
      string.slice(10, 16) +
      " " +
      string.slice(8, 10) +
      "." +
      string.slice(5, 7) +
      "." +
      string.slice(0, 4);
    return returnDateTime;
  };

  /** Garantie Fll */
  useEffect(() => {
    const getInfosByUsingLagerSGTIN = () => {
      setProgressSchaeffler(true);

      axios
        .get(process.env.REACT_APP_BACKEND_SERVER + "/getInfosByUsingLagerSGTIN", {
          params: {
            lagerSGTIN: inputID,
          },
        })

        .then((response) => {
          console.log(response);
          delay(1500).then(() => {
            setErrorInput(false);
            setProgressSchaeffler(false);
            console.log(response.data.historikDaten.revisionArrayList);
            saveLagerInfoDataObject(response.data);
            saveHistorikDataObject(
              response.data.historikDaten.revisionArrayList
            );
            setInputID("");
          });
        })
        .catch(() => {
          delay(800).then(() => {
            setProgressSchaeffler(false);

            setErrorInput(true);
          });
        });
      axios({
        url: process.env.REACT_APP_BACKEND_SERVER + "/getpdf/" + inputID,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        setFilePDF(new Blob([response.data]));
        setFilePathPDFMontage("/mounting.pdf");
        setFilePathPDFWerkzeug("/Tools.pdf");
      });
    };
    const type = checkTypeOfID(inputID);
    if (type.includes("L-")) getInfosByUsingLagerSGTIN();

    if (inputID.length > 42) {
      setErrorInput(true);
    }
  }, []);

  const handleClosePDF = () => {
    setOpenPDF(false);
  };
  const handleOpenPDF = () => {
    setOpenPDF(true);
  };
  const handleClosePDFWerkzeug = () => {
    setOpenPDFWerkzeug(false);
  };
  const handleOpenPDFWerkzeug = () => {
    setOpenPDFWerkzeug(true);
  };
  const handleClosePDFMontage = () => {
    setOpenPDFMontage(false);
  };
  const handleOpenPDFMontage = () => {
    setOpenPDFMontage(true);
  };

  const [openNormalLoginDialog, setOpenNormalLoginDialog] = useState(false);
  const [normalLogin, setNormalLogin] = useState(false);
  const handleCloseNormalLoginDialog = () => {
    setOpenNormalLoginDialog(false);
  };
  const handleLoginNormal = () => {
    setOpenNormalLoginDialog(false);
    setNormalLogin(true);
  };
  const handleLogoutNormal = () => {
    setOpenNormalLoginDialog(false);
    setNormalLogin(false);
  };
  return (
    <Box sx={{ flexGrow: 1 }} className={classes.root}>
      <Header
        setOpenNormalLoginDialog={() => setOpenNormalLoginDialog(true)}
        normalLogin={normalLogin}
        handleCloseNormalLoginDialog={handleCloseNormalLoginDialog}
      ></Header>
      <NormalLoginDialog
        open={openNormalLoginDialog}
        logged={normalLogin}
        handleClose={() => handleCloseNormalLoginDialog}
        handleLogin={() => handleLoginNormal}
        handleLogout={() => handleLogoutNormal}
      />
      <Grid className={classes.mainContainer}>
        <PDFDialog
          openPDF={openPDF}
          handleClosePDF={handleClosePDF}
          filePDF={filePDF}
        />
        <PDFDialogMontage
          openPDF={openPDFMontage}
          handleClosePDF={handleClosePDFMontage}
          filePDF={filePathPDFMontage}
        />
        <PDFDialogWerkzeug
          openPDF={openPDFWerkzeug}
          handleClosePDF={handleClosePDFWerkzeug}
          filePDF={filePathPDFWerkzeug}
        />
        <HistorikDataDialog
          openHistorik={openHistorik}
          handleCloseHistorik={handleCloseHistorik}
          rows={historikDataRows}
        />
        <NachhaltigkeitDialog
          openNachhaltigkeit={openNachhaltigkeit}
          handleCloseNachhaltigkeit={handleCloseNachhaltigkeit}
          rows={nachhaltigkeitRows}
        />
        <RadsatzMontageDialog
          openMontage={openMontage}
          handleCloseMontage={handleCloseMontage}
        />
        <Grid container spacing={12}>
          <Grid item xs={4} handy={12} margin="auto">
            <SchaefflerComponent
              lagerSGTIN={inputID}
              errorValid={errorInput}
              progress={progressSchaeffler}
              classes={classes}
              rows={lagerInfo}
              pairValueColor={pairValueColor}
              handleClosePDF={handleClosePDF}
              handleOpenPDF={handleOpenPDF}
              handleClosePDFWerkzeug={handleClosePDFWerkzeug}
              handleOpenPDFWerkzeug={handleOpenPDFWerkzeug}
              handleClosePDFMontage={handleClosePDFMontage}
              handleOpenPDFMontage={handleOpenPDFMontage}
              openPDF={openPDF}
              handleOpenHistorik={handleOpenHistorik}
              handleOpenNachhaltigkeit={handleOpenNachhaltigkeit}
              normalLogin={normalLogin}
              gtin={gtin}
              serial_number={serial_number}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default App;
