/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import Paper from "@mui/material/Paper";
import { Item } from "./Item";
import CircularProgress from "@mui/material/CircularProgress";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  TableContainer,
  TableCell,
  TableRow,
  IconButton,
  TableFooter,
  Table,
  TableBody,
  Button,
} from "@mui/material";
import QRcode from "qrcode.react";

import { pdfjs } from "react-pdf";
import { useTranslation, i18n } from "react-i18next";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function SchaefflerComponent(props) {
  const {
    classes,
    rows,
    progress,
    pairValueColor,
    handleOpenPDF,
    handleOpenPDFWerkzeug,
    handleOpenPDFMontage,
    handleOpenHistorik,
    qr,
  } = props;
  const { t, i18n } = useTranslation();

  return (
    <Item style={{ height: "fit-content" }}>
      <Box className={classes.boxIconDatabase}>
        <img
          alt="schaefflerIcon"
          src="./schaefflerIcon.jpg"
          style={{ height: "25px", width: "250px", marginTop: "10px" }}
        ></img>
        <form style={{ marginTop: "10px" }}></form>
        <CircularProgress
          color="success"
          sx={
            progress ? { display: "flex", margin: "auto" } : { display: "none" }
          }
        />
        <img
          alt="datenbank"
          src="./datenbank-foto.jpg"
          className={classes.photoDatabase}
        ></img>
      </Box>

      <Box className={classes.boxTable}>
        <Paper className={classes.titleInfo}>{t("Lager-Infos")}</Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableBody>
              {rows.map((row) => {
                if (row.name !== null && row.name === "HistorikDaten") {
                  return (
                    <TableRow key={row.name}>
                      <TableCell
                        style={
                          row.value === pairValueColor && pairValueColor !== ""
                            ? { background: "#003060", color: "#ffff" }
                            : {}
                        }
                        component="th"
                        scope="row"
                      >
                        {t(row.name)}
                      </TableCell>
                      <TableCell
                        style={
                          row.value === pairValueColor && pairValueColor !== ""
                            ? {
                                width: 160,
                                background: "#003060",
                                color: "#ffff",
                              }
                            : { width: 160 }
                        }
                        align="right"
                      >
                        <IconButton
                          color="default"
                          onClick={handleOpenHistorik}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                } else if (row.name === "Zeugnis") {
                  return (
                    <TableRow key={row.name}>
                      <TableCell
                        style={
                          row.value === pairValueColor && pairValueColor !== ""
                            ? { background: "#003060", color: "#ffff" }
                            : {}
                        }
                        component="th"
                        scope="row"
                      >
                        {t(row.name)}
                      </TableCell>
                      <TableCell
                        style={
                          row.value === pairValueColor && pairValueColor !== ""
                            ? {
                                width: 160,
                                background: "#003060",
                                color: "#ffff",
                              }
                            : { width: 160 }
                        }
                        align="right"
                      >
                        <IconButton color="default" onClick={handleOpenPDF}>
                          <MoreHorizIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                } else if (row.name === "Montage") {
                  return (
                    <TableRow key={row.name}>
                      <TableCell
                        style={
                          row.value === pairValueColor && pairValueColor !== ""
                            ? { background: "#003060", color: "#ffff" }
                            : {}
                        }
                        component="th"
                        scope="row"
                      >
                        {t(row.name)}
                      </TableCell>
                      <TableCell
                        style={
                          row.value === pairValueColor && pairValueColor !== ""
                            ? {
                                width: 160,
                                background: "#003060",
                                color: "#ffff",
                              }
                            : { width: 160 }
                        }
                        align="right"
                      >
                        <IconButton color="default" onClick={handleOpenPDFMontage}>
                          <MoreHorizIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                } else if (row.name === "Werkzeug") {
                  return (
                    <TableRow key={row.name}>
                      <TableCell
                        style={
                          row.value === pairValueColor && pairValueColor !== ""
                            ? { background: "#003060", color: "#ffff" }
                            : {}
                        }
                        component="th"
                        scope="row"
                      >
                        {t(row.name)}
                      </TableCell>
                      <TableCell
                        style={
                          row.value === pairValueColor && pairValueColor !== ""
                            ? {
                                width: 160,
                                background: "#003060",
                                color: "#ffff",
                              }
                            : { width: 160 }
                        }
                        align="right"
                      >
                        <IconButton color="default" onClick={handleOpenPDFWerkzeug}>
                          <MoreHorizIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                }
                else {
                  return (
                    <TableRow key={row.name}>
                      <TableCell
                        style={
                          row.value === pairValueColor && pairValueColor !== ""
                            ? { background: "#003060", color: "#ffff" }
                            : {}
                        }
                        component="th"
                        scope="row"
                      >
                        {t(row.name)}
                      </TableCell>
                      <TableCell
                        style={
                          row.value === pairValueColor && pairValueColor !== ""
                            ? {
                                width: 160,
                                background: "#003060",
                                color: "#ffff",
                              }
                            : { width: 160 }
                        }
                        align="right"
                      >
                        {row.value}
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
              <TableRow>
                <TableCell>Digital Product Pass</TableCell>
                <TableCell style={{ padding: "10px" }} align="right">
                  {qr ? (
                    <Button href={qr}>
                      <QRcode id="myqr" value={qr} size={120} />
                    </Button>
                  ) : (
                    <p></p>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </Item>
  );
}
