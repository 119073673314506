import React from "react";
import Paper from "@mui/material/Paper";
import { Item } from "./Item";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation, i18n } from "react-i18next";

import {
  Box,
  TableContainer,
  TableCell,
  TableRow,
  Button,
  TableFooter,
  Collapse,
  Table,
  TableBody,
} from "@mui/material";

function seedRandom(seed, range) {
  let x = Math.sin(seed) * 10000;
  const randomValue = x - Math.floor(x);

  let min, max;
  if (range === "first") {
    min = 0;
    max = 0.5;
  } else if (range === "second") {
    min = 1;
    max = 2;
  } else {
    throw new Error('Invalid range specified. Use "first" or "second".');
  }

  const scaledValue = min + randomValue * (max - min);

  return Math.round(scaledValue * 10) / 10;
}

const COEFF_ANIMATION_URL = "https://files.eecc.info/f/5b3abcc925a24fa49ff9/";

function convertToUnixTs(dateString) {
  const [time, date] = dateString.trimStart().split(" ");
  const [hours, minutes] = time.split(":").map(Number);
  const [day, month, year] = date.split(".").map(Number);
  const dateObj = new Date(year, month - 1, day, hours, minutes);
  return Math.floor(dateObj.getTime() / 1000);
}

export default function SbbComponent(props) {
  const { classes, rows, progress, pairValueColor } = props;
  const [openMessLocation, setOpenMessLocation] = React.useState(false);
  const { t, i18n } = useTranslation();

  return (
    <Item style={{ height: "fit-content" }}>
      <Box className={classes.boxIconDatabase}>
        {/* <span
          style={{
            height: "25px",
            width: "250px",
            fontFamily: "fantasy",
            fontSize: "1.6rem",
            color: "#C70039",
          }}
        >
          {t("schweizerBahnbetreiber")}
        </span> */}
        <img
          alt="sbbIcon"
          src="./sbbIcon.jpg"
          style={{ height: "25px", width: "250px", marginTop: "10px" }}
        ></img>
        <form style={{ marginTop: "10px" }}></form>
        <CircularProgress
          color="error"
          sx={
            progress ? { display: "flex", margin: "auto" } : { display: "none" }
          }
        />

        <img
          alt="datenbank"
          src="./datenbank-foto.jpg"
          className={classes.photoDatabase}
        ></img>
      </Box>

      <Box className={classes.boxTable}>
        <Paper className={classes.titleInfo}>{t("DiagnoseDaten")}</Paper>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableBody>
              {rows.map((row) => {
                if (row.name === "LagerPosition") {
                  if (row.value !== undefined && row.value !== null) {
                    return (
                      <TableRow
                        key={row.name}
                        style={
                          openMessLocation || progress
                            ? { display: "none" }
                            : null
                        }
                      >
                        <TableCell component="th" scope="row">
                          {t(row.name)}
                        </TableCell>

                        <TableCell
                          style={{ width: 160, fontSize: "small" }}
                          align="right"
                        >
                          <div>
                            {t("ImDrehgestell")}:{" "}
                            {row.value.positionImDrehgestell
                              ? row.value.positionImDrehgestell
                              : null}{" "}
                          </div>
                          <div>
                            {t("ImRadsatz")}:{" "}
                            {row.value.positionImRadsatz
                              ? row.value.positionImRadsatz
                              : null}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  }
                }

                if (row.name === "Mess-Location") {
                  if (row.value !== undefined && row.value !== null) {
                    return (
                      <>
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {t(row.name)}
                          </TableCell>

                          <TableCell
                            style={{ width: 160, fontSize: "small" }}
                            align="right"
                          >
                            <div>
                              lat. {row.value.messLocation.split(",")[0]}
                            </div>
                            <div>
                              long. {row.value.messLocation.split(",")[1]}
                            </div>
                            <div>
                              <a
                                href={`https://www.google.com/maps?q=${
                                  row.value.messLocation.split(",")[0]
                                },${row.value.messLocation.split(",")[1]}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {t("linkGoogleMaps")}
                              </a>
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  }
                }
                if (row.name === "SensorDaten") {
                  if (
                    row.value !== null &&
                    (row.value["dynamicCoefficient.1L"] !== undefined ||
                      row.value["dynamicCoefficient.1R"] !== undefined ||
                      row.value["dynamicCoefficient.2L"] !== undefined ||
                      row.value["dynamicCoefficient.2R"] !== undefined) &&
                    (row.value["dynamicCoefficient.1L"] !== null ||
                      row.value["dynamicCoefficient.1R"] !== null ||
                      row.value["dynamicCoefficient.2L"] !== null ||
                      row.value["dynamicCoefficient.2R"] !== null)
                  ) {
                    return (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {t(row.name)}
                        </TableCell>

                        <TableCell style={{ fontSize: "small" }} align="right">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              justifyItems: "start",
                            }}
                          >
                            {/* Dynamic Coefficients Section */}
                            <a
                              href={COEFF_ANIMATION_URL}
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                alignContent: "stretch",
                                justifyContent: "space-evenly",
                                alignItems: "flex-start",
                                textDecoration: "none", // Optional: removes underline from the link
                                color: "inherit", // Optional: keeps text color unchanged
                              }}
                            >
                              <div>
                                <strong>{t("dynamicCoefficients")}</strong>
                              </div>
                              <div>
                                {"1L: "}
                                {row.value["dynamicCoefficient.1L"]}
                              </div>
                              <div>
                                {"1R: "}
                                {row.value["dynamicCoefficient.1R"]}
                              </div>
                              <div>
                                {"2L: "}
                                {row.value["dynamicCoefficient.2L"]}
                              </div>
                              <div>
                                {"2R: "}
                                {row.value["dynamicCoefficient.2R"]}
                              </div>
                            </a>

                            {/* Acoustic Data Section */}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                alignContent: "stretch",
                                justifyContent: "space-evenly",
                                alignItems: "flex-start",
                              }}
                            >
                              <div>
                                <strong>{t("AkustikDaten")}</strong>
                              </div>
                              <div>
                                {"acuIndOutBearAxle: "}
                                {seedRandom(
                                  convertToUnixTs(
                                    rows.filter(
                                      (r) => r.name === "Messzeitpunkt"
                                    )[0]?.value
                                  ),
                                  "first"
                                )}
                              </div>
                              <div>
                                {"acuIndInnBearAxle: "}
                                {seedRandom(
                                  convertToUnixTs(
                                    rows.filter(
                                      (r) => r.name === "Messzeitpunkt"
                                    )[0]?.value
                                  ) + 1,
                                  "first"
                                )}
                              </div>
                              <div>
                                {"acuIndRolarAxle: "}
                                {seedRandom(
                                  convertToUnixTs(
                                    rows.filter(
                                      (r) => r.name === "Messzeitpunkt"
                                    )[0]?.value
                                  ) + 2,
                                  "first"
                                )}
                              </div>
                              <div>
                                {"indOuRol2x: "}
                                {seedRandom(
                                  convertToUnixTs(
                                    rows.filter(
                                      (r) => r.name === "Messzeitpunkt"
                                    )[0]?.value
                                  ) + 3,
                                  "second"
                                )}
                              </div>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  } else {
                    return (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {t(row.name)}
                        </TableCell>
                        <TableCell
                          style={{ width: 160 }}
                          align="right"
                        ></TableCell>
                      </TableRow>
                    );
                  }
                }
                if (row.name === "WagonNummer") {
                  return (
                    <TableRow key={row.name}>
                      <TableCell
                        style={
                          row.value === pairValueColor && pairValueColor !== ""
                            ? {
                                background: "#003060",
                                color: "#ffff",
                              }
                            : null
                        }
                        component="th"
                        scope="row"
                      >
                        {t(row.name)}
                      </TableCell>

                      <TableCell
                        style={
                          row.value === pairValueColor && pairValueColor !== ""
                            ? {
                                width: 160,
                                background: "#003060",
                                color: "#ffff",
                              }
                            : { width: 160 }
                        }
                        align="right"
                      >
                        <a
                          href={`https://${process.env.REACT_APP_USERNAME}:${
                            process.env.REACT_APP_PASSWORD
                          }@${
                            process.env.REACT_APP_DISCOVERY_HOST
                          }/train-info/${row.value?.slice(-12)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row.value}
                        </a>
                      </TableCell>
                    </TableRow>
                  );
                }
                if (row.name === "KmLaufleistung") {
                  return null;
                } else {
                  return (
                    <TableRow key={row.name}>
                      <TableCell
                        style={
                          row.value === pairValueColor && pairValueColor !== ""
                            ? {
                                background: "#003060",
                                color: "#ffff",
                              }
                            : null
                        }
                        component="th"
                        scope="row"
                      >
                        {t(row.name)}
                      </TableCell>

                      <TableCell
                        style={
                          row.value === pairValueColor && pairValueColor !== ""
                            ? {
                                width: 160,
                                background: "#003060",
                                color: "#ffff",
                              }
                            : { width: 160 }
                        }
                        align="right"
                      >
                        {row.value}
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </Item>
  );
}
