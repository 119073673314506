import {
  AppBar,
  Grid,
  Box,
  TextField,
  Button,
  IconButton,
  Menu,
  Paper,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LoginIcon from "@mui/icons-material/Login";
import { makeStyles } from "@mui/styles";
import LanguageIcon from "@mui/icons-material/Language";
import React from "react";
import { useTranslation, i18n } from "react-i18next";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
/**
 * Best-Practice wenn man mit Darkmode und Grids arbeiten möchte und das bevorzugute Styling von Material-UI nutzt.
 */
const useStyles = makeStyles((theme) => ({
  appbar: {
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      height: "6vh",
      padding: "15px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      height: "6vh",
      padding: "10px",
      marginBottom: "1rem",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: "6vh",
      padding: "10px",
      marginBottom: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "6vh",
      padding: "10px",
    },
  },
  paper: {
    height: "13vh",
  },
  gs1Icon: {
    height: "90%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "0%",
      width: "0%",
    },
  },
  eeccIcon: {
    height: "90%",
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      height: "0%",
      width: "0%",
    },
  },
  inputBox: {
    [theme.breakpoints.up("lg")]: {
      height: "7h",
    },
    [theme.breakpoints.between("md", "lg")]: {
      height: "6vh",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: "12vh",
    },
    [theme.breakpoints.down("sm")]: {
      height: "12vh",
    },
  },
  inputfield: {
    height: "4vh",
  },
  languageIcon: {
    marginLeft: "0 !important",
    marginRight: "auto !important",
  },
  loginIcon: {
    marginLeft: "auto !important",
    marginRight: "0 !important",
  },
  languageButton: {
    marginBottom: "0.5rem",
  },
  titleBox: {
    color: theme.palette.text.title,
    textAlign: "center",
    fontWeight: "bold",

    [theme.breakpoints.up("lg")]: {
      height: "4vh",
      fontSize: "3rem",
      marginBottom: "1vh",
    },
    [theme.breakpoints.between("md", "lg")]: {
      height: "4vh",
      fontSize: "2rem",
      marginBottom: "1vh",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: "4vh",
      fontSize: "1.4rem",
      marginBottom: "1vh",
    },
    [theme.breakpoints.down("sm")]: {
      height: "4vh",
      fontSize: "0.7rem",
      marginBottom: "1vh",
    },
  },
  konfiguration: {
    margin: "auto",
    paddingTop: "0.6vh",
    border: "6px solid " + theme.palette.borderColor,
    padding: "0 !important",
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const {
    handleCloseNormalLoginDialog,
    normalLogin,
    setOpenNormalLoginDialog,
  } = props;
  const [anchorElLng, setAnchorElLng] = React.useState(null);
  const [iconLanguge, setIconLanguge] = React.useState(
    "/icons8-germany-40.png"
  );

  const handleChangeLng = (e) => {
    switch (e.target.innerText) {
      case "Deutsch":
        i18n.changeLanguage("de-DE");
        setIconLanguge("/icons8-germany-40.png");
        break;
      case "Englisch":
        i18n.changeLanguage("en-US");
        setIconLanguge("/icons8-usa-40.png");
        break;
      case "Français":
        i18n.changeLanguage("fr-FR");
        setIconLanguge("/icons8-france-40.png");
        break;
      default:
        return;
    }
    setAnchorElLng(null);
  };
  const handleOpen = (e) => {
    setAnchorElLng(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorElLng(null);
  };
  const { t, i18n } = useTranslation();

  const lngMenuItems = [
    {
      title: "Deutsch",
    },
    {
      title: "Englisch",
    },
    {
      title: "Français",
    },
  ];
  return (
    <AppBar position="absolut" className={classes.appbar}>
      {" "}
      <Box className={classes.paper}>
        <Grid className={classes.titleBox} container>
          <Grid item className={classes.languageIcon} xs={5}>
            <IconButton
              onClick={handleOpen}
              style={{ padding: "0 1rem 0.5rem" }}
            >
              <img
                style={{
                  width: "10vw",
                  height: "3.5vh",
                  maxWidth: "fit-content",
                }}
                src={iconLanguge}
              ></img>
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorElLng}
              keepMounted
              open={Boolean(anchorElLng)}
              onClose={handleClose}
            >
              {lngMenuItems.map((item) => (
                <MenuItem
                  onClick={(item) => handleChangeLng(item)}
                  key={item.title}
                  value={item.title}
                  style={{ width: "30vw" }}
                >
                  {item.title}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
          <Grid item className={classes.loginIcon} xs={2}>
            <Button
              onClick={() => setOpenNormalLoginDialog(true)}
              size="small"
              variant="outlined"
              color="success"
            >
              {normalLogin ? t("Logout") : t("Login")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </AppBar>
  );
};

export default Header;
