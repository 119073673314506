import "./App.css";
import axios from "axios";
import { Box, Snackbar, Alert, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import SchaefflerComponent from "./Components_Desktop/SchaefflerComponent";
import StadlerComponent from "./Components_Desktop/StadlerComponent";
import SbbComponent from "./Components_Desktop/SbbComponent";
import Footer from "./Components_Desktop/Footer";
import { makeStyles } from "@mui/styles";
import Header from "./Components_Desktop/Header";
import { checkTypeOfID, harmonizeInputId, delay } from "./utils";
import PDFDialog from "./Components_Desktop/PDFDialog";
import PDFDialogMontage from "./Components_Desktop/PDFDialogMontage";
import PDFDialogWerkzeug from "./Components_Desktop/PDFDialogWerkzeug";
import HistorikDataDialog from "./Components_Desktop/HistorikDataDialog";
import RadsatzMontageDialog from "./Components_Desktop/RadsatzMontageDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "100vh",
    width: "100vw",
    alignItems: "center",
  },
  appbar: {
    textAlign: "center",
    //position: "absolut",
    height: "15vh",
  },

  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      width: "100vw",
      minHeight: "82.95vh",
      height: "fit-content",
      padding: "0.1rem 1rem 0 1rem",
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "100vw",
      minHeight: "calc(100% - 165px)",
      height: "fit-content",

      padding: "0.1rem 1rem 0 1rem",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "100vw",
      minHeight: "84.4vh",
      height: "fit-content",

      padding: "0.1rem 1rem 0 1rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      minHeight: "84.4vh",
      height: "fit-content",

      padding: "0.1rem 1rem 0 1rem",
    },
  },
  photoDatabase: {
    [theme.breakpoints.down("md")]: {
      marginTop: "1em",
      height: "5em",
      width: "5em",
    },
    [theme.breakpoints.between("md", "lg")]: {
      marginTop: "1em",
      height: "7em",
      width: "7em",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "1em",
      height: "8em",
      width: "8em",
    },
  },
  titleInfo: {
    fontSize: "200%",
    boxShadow: "none !important",
    margin: "10px",
    color: theme.palette.text.option2,
  },
  boxTable: {
    margin: "10px",
    // borderTop: "8px solid " + theme.palette.borderColor,
  },
  boxIconDatabase: {
    marginTop: "8px",
    borderBottom: "8px solid " + theme.palette.borderColor,
  },
  pdf: { height: "10vh" },
}));

const App = () => {
  const [pairValueColor, setPairValueColor] = useState("");
  /*  const [inputID, setInputID] =
    useState(""); */
  const [filePDF, setFilePDF] = useState("");
  const [filePathPDFWerkzeug, setFilePathPDFWerkzeug] = useState("");
  const [filePathPDFMontage, setFilePathPDFMontage] = useState("");

  const [inputID, setInputID] = useState("");
  const [lagerInfo, setLagerInfo] = useState([
    { name: "Lager-ID", value: inputID },
    { name: "Produktionsdatum", value: "" },
    { name: "Produktionswerk", value: "" },
    { name: "OrderNummer", value: "" },
    { name: "Zeugnis", value: "" },
    { name: "Montage", value: "" },
    { name: "Werkzeug", value: "" },
    { name: "HistorikDaten", value: "" },
  ]);
  const [qr, setQr] = React.useState("");
  const handleChangeQR = (event) => {
    setQr(event.target.value);
  };
  const [konfiguration, setKonfiguration] = useState([
    { name: "HauptLager-ID", value: "" },
    { name: "LagerID1", value: "" },
    { name: "LagerID2", value: "" },
    { name: "LagerID3", value: "" },
    { name: "LagerID4", value: "" },
    { name: "LagerID5", value: "" },
    { name: "LagerID6", value: "" },
    { name: "LagerID7", value: "" },
    { name: "LagerID8", value: "" },
    { name: "HauptRadsatz", value: "" },
    { name: "RadsatzID1", value: "" },
    { name: "RadsatzID2", value: "" },
    { name: "RadsatzID3", value: "" },
    { name: "RadsatzID4", value: "" },
    { name: "HauptDrehgestell", value: "" },
    { name: "DrehgestellID1", value: "" },
    { name: "DrehgestellID2", value: "" },
    { name: "HauptWagonNummer", value: "" },
  ]);
  const [diagnoseDaten, setDiagnoseDaten] = useState([
    { name: "Lager-ID", value: null },
    { name: "KmLaufleistung", value: null },
    { name: "SensorDaten", value: null },
    { name: "Messzeitpunkt", value: null },
    { name: "Mess-Location", value: null },
    { name: "WagonNummer", value: null },
  ]);
  const classes = useStyles();

  const [errorInput, setErrorInput] = useState(false);

  const [progressSchaeffler, setProgressSchaeffler] = useState(false);

  const [progressStadler, setProgressStadler] = useState(false);
  const [progressSBB, setProgressSBB] = useState(false);
  const [sendIDFromGarantieFall, setSendLagerFromGarantieFall] =
    useState(false);
  const [sendIDFromKonfigurationFall, setSendLagerFromKonfigurationFall] =
    useState(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const handleCloseSuccess = () => {
    setOpenAlertSuccess(false);
  };
  const [openAlertError, setOpenAlertError] = useState(false);
  const [openAlertErrorSyntax, setOpenAlertErrorSyntax] = useState(false);
  const [openPDF, setOpenPDF] = useState(false);
  const [openPDFWerkzeug, setOpenPDFWerkzeug] = useState(false);
  const [openPDFMontage, setOpenPDFMontage] = useState(false);
  const [openHistorik, setOpenHistorik] = useState(false);
  const [openMontage, setOpenMontage] = useState(false);
  const [radsatzIndex, setRadsatzIndex] = useState("");

  const [historikDataRows, setHistorikDataRows] = useState([
    {
      datum: "2010-03-01",
      status: "Lager-Schrott",
      revisionswerk: "-----",
      betriebskilometer: "1950000",
      fehlerCode: "Stromdurchgang",
      revisionsauftrag: "Demo Lager",
      lagerhesteller: "Demo Hersteller",
      produktionsland: "Demo Land",
      axialspiel: "-----",
      fettCharge: "-----",
      fettDatum: "-----",
      fettGewicht: "-----",
    },
  ]);

  const handleCloseMontage = () => {
    setOpenMontage(false);
  };
  const handleOpenMontage = (radsatzIndex) => {
    console.log("radsatzIndex: " + radsatzIndex);
    setRadsatzIndex(radsatzIndex);

    setOpenMontage(true);
  };
  const handleOpenHistorik = () => {
    setOpenHistorik(true);
  };
  const handleCloseHistorik = () => {
    setOpenHistorik(false);
  };
  const handleCloseErrorAxios = () => {
    setOpenAlertError(false);
  };
  const handleCloseErrorSyntax = () => {
    setOpenAlertErrorSyntax(false);
  };
  const resetLagerInfo = () => {
    setLagerInfo([
      {
        name: "Lager-ID",
        value: "",
      },
      { name: "Produktionsdatum", value: "" },
      { name: "Produktionswerk", value: "" },
      { name: "OrderNummer", value: "" },
      { name: "Zeugnis", value: "" },
      { name: "Werkzeug", value: "" },
      { name: "Montage", value: "" },
      { name: "HistorikDaten", value: "" },
    ]);
  };
  const resetKonfigurationInfo = () => {
    setKonfiguration([
      { name: "HauptLager-ID", value: "" },
      {
        name: "LagerID1",
        value: "",
      },
      {
        name: "LagerID2",
        value: "",
      },
      {
        name: "LagerID3",
        value: "",
      },
      {
        name: "LagerID4",
        value: "",
      },
      {
        name: "LagerID5",
        value: "",
      },
      {
        name: "LagerID6",
        value: "",
      },
      {
        name: "LagerID7",
        value: "",
      },
      {
        name: "LagerID8",
        value: "",
      },
      { name: "HauptRadsatz", value: "" },
      { name: "RadsatzID1", value: "", subValue1: "", subValue2: "" },
      { name: "RadsatzID2", value: "", subValue1: "", subValue2: "" },
      { name: "RadsatzID3", value: "", subValue1: "", subValue2: "" },
      { name: "RadsatzID4", value: "", subValue1: "", subValue2: "" },
      { name: "HauptDrehgestell", value: "" },
      {
        name: "DrehgestellID1",
        value: "",
        subValue1: "",
        subValue2: "",
      },
      {
        name: "DrehgestellID2",
        value: "",
        subValue1: "",
        subValue2: "",
      },
      { name: "HauptWagonNummer", value: "" },
    ]);
  };
  const resetDiagnosenDaten = () => {
    setDiagnoseDaten([
      { name: "Lager-ID", value: "" },
      { name: "KmLaufleistung", value: "" },
      { name: "AkustikDaten", value: "" },
      { name: "Messzeitpunkt", value: "" },
      { name: "Mess-Location", value: "" },
      { name: "WagonNummer", value: "" },
    ]);
  };
  const saveLagerInfoDataObject = (data) => {
    var parts = data.produktionsdatum.split("-");
    let date = new Date(parts[0], parts[1] - 1, parts[2]);

    /*  let dataObject = [
      {
        name: "Lager-ID",
        value: inputID
          ? inputID
          : inputIDInGarantieFall,
      },
      { name: "Produktionsdatum", value: date.toLocaleDateString() },
      { name: "Produktionswerk", value: data.produktionsWerk },
      { name: "OrderNummer", value: data.orderNumber },
    ]; */
    
    setLagerInfo(
      lagerInfo.map((lager) =>
        lager.name === "Lager-ID"
          ? {
              ...lager,
              name: "Lager-ID",
              value: inputID ? inputID : data.lagerGIAI,
            }
          : lager.name === "Produktionsdatum"
          ? {
              ...lager,
              name: "Produktionsdatum",
              value: date.toLocaleDateString(),
            }
          : lager.name === "Produktionswerk"
          ? { ...lager, name: "Produktionswerk", value: data.produktionsWerk }
          : lager.name === "OrderNummer"
          ? { ...lager, name: "OrderNummer", value: data.orderNumber }
          : lager.name === "Zeugnis"
          ? { ...lager, name: "Zeugnis", value: data.pdfName }
          : lager.name === "Werkzeug"
          ? { ...lager, name: "Werkzeug", value: "" }
          : lager.name === "Montage"
          ? { ...lager, name: "Montage", value: "" }
          : lager.name === "HistorikDaten"
          ? {
              ...lager,
              name: "HistorikDaten",
              value: data.historikDaten.revisionArrayList,
            }
          : { ...lager, name: "", value: "" }
      )
    );
  };
  const saveHistorikDataObject = (data) => {
    setHistorikDataRows(data);
  };
  const changeToGermanDateTime = (string) => {
    let returnDateTime =
      string.slice(10, 16) +
      " " +
      string.slice(8, 10) +
      "." +
      string.slice(5, 7) +
      "." +
      string.slice(0, 4);
    return returnDateTime;
  };
  const saveDiagnoseDataObject = (data) => {
    let object = [
      { name: "Lager-ID", value: data.lagerGIAI },
      { name: "KmLaufleistung", value: data.diagnoseDaten.distanz },
      { name: "SensorDaten", value: data.diagnoseDaten.sensorDaten },
      {
        name: "Messzeitpunkt",
        value: changeToGermanDateTime(data.diagnoseDaten.messZeitpunkt),
      },
      {
        name: "Mess-Location",
        value: data.diagnoseDaten.messLocation,
      },
      {
        name: "LagerPosition",
        value: data.position,
      },
      { name: "WagonNummer", value: data.konfiguration.wagenGIAI },
    ];
    setDiagnoseDaten(object);
  };

  const saveKonfigurationDataObject = (data) => {
    console.log(data);
    let object = [
      { name: "HauptLager-ID", value: data.lagerGIAI ? data.lagerGIAI : "" },
      {
        name: "LagerID1",
        value: data.konfiguration.alleLagerIDs
          ? data.konfiguration.alleLagerIDs[0]
          : "",
      },
      {
        name: "LagerID2",
        value: data.konfiguration.alleLagerIDs
          ? data.konfiguration.alleLagerIDs[1]
          : "",
      },
      {
        name: "LagerID3",
        value: data.konfiguration.alleLagerIDs
          ? data.konfiguration.alleLagerIDs[2]
          : "",
      },
      {
        name: "LagerID4",
        value: data.konfiguration.alleLagerIDs
          ? data.konfiguration.alleLagerIDs[3]
          : "",
      },
      {
        name: "LagerID5",
        value: data.konfiguration.alleLagerIDs
          ? data.konfiguration.alleLagerIDs[4]
          : "",
      },
      {
        name: "LagerID6",
        value: data.konfiguration.alleLagerIDs
          ? data.konfiguration.alleLagerIDs[5]
          : "",
      },
      {
        name: "LagerID7",
        value: data.konfiguration.alleLagerIDs
          ? data.konfiguration.alleLagerIDs[6]
          : "",
      },
      {
        name: "LagerID8",
        value: data.konfiguration.alleLagerIDs
          ? data.konfiguration.alleLagerIDs[7]
          : "",
      },
      { name: "HauptRadsatz", value: data.diesenLagerIstImRadsatz },
      {
        name: "RadsatzID1",
        value: data.konfiguration.alleRadsatz[0].radsatzID,
        subValue1: data?.konfiguration?.alleRadsatz[0].lagerIDs[0],
        subValue2: data.konfiguration.alleRadsatz[0].lagerIDs[1],
      },
      {
        name: "RadsatzID2",
        value: data.konfiguration.alleRadsatz[1].radsatzID,
        subValue1: data.konfiguration.alleRadsatz[1].lagerIDs[0],
        subValue2: data.konfiguration.alleRadsatz[1].lagerIDs[1],
      },
      {
        name: "RadsatzID3",
        value: data.konfiguration.alleRadsatz[2].radsatzID,
        subValue1: data.konfiguration.alleRadsatz[2].lagerIDs[0],
        subValue2: data.konfiguration.alleRadsatz[2].lagerIDs[1],
      },
      {
        name: "RadsatzID4",
        value: data.konfiguration.alleRadsatz[3].radsatzID,
        subValue1: data.konfiguration.alleRadsatz[3].lagerIDs[0],
        subValue2: data.konfiguration.alleRadsatz[3].lagerIDs[1],
      },
      { name: "HauptDrehgestell", value: data.diesenLagerIstImDrehgestell },
      {
        name: "DrehgestellID1",
        value: data.konfiguration.alleDrehgestell[0].drehgestellID,
        subValue1: data.konfiguration.alleDrehgestell[0].radsatzIDs[0],
        subValue2: data.konfiguration.alleDrehgestell[0].radsatzIDs[1],
      },
      {
        name: "DrehgestellID2",
        value: data.konfiguration.alleDrehgestell[1].drehgestellID,
        subValue1: data.konfiguration.alleDrehgestell[1].radsatzIDs[0],
        subValue2: data.konfiguration.alleDrehgestell[1].radsatzIDs[1],
      },
      { name: "HauptWagonNummer", value: data.konfiguration.wagenGIAI },
    ];
    setKonfiguration(object);
  };
  /** Garantie Fll */
  useEffect(() => {
    const getInfosByUsingLagerSGTIN = (id) => {
      setProgressSchaeffler(true);
      delay(500).then(() => {
        setProgressStadler(true);
        delay(500).then(() => {
          setProgressSBB(true);
        });
      });
      axios
        .get(process.env.REACT_APP_BACKEND_SERVER + "/getInfosByUsingLagerSGTIN", {
          params: {
            lagerSGTIN: id,
          },
        })

        .then((response) => {
          console.log(response);
          delay(1500).then(() => {
            setErrorInput(false);
            setProgressSchaeffler(false);
            setProgressStadler(false);
            setProgressSBB(false);
            var gtin =
              response.data.lagerGIAI.slice(17, 24) +
              response.data.lagerGIAI.slice(26, 31);
            var serialnumber = response.data.lagerGIAI.slice(32, 43);
            setQr("https://id.eecc.de/01/0" + gtin + "/21/" + serialnumber);
            //setQr("http://localhost:3000/01/0" + gtin + "/21/" + serialnumber);

            console.log(response.data.historikDaten.revisionArrayList);
            saveLagerInfoDataObject(response.data);
            saveHistorikDataObject(
              response.data.historikDaten.revisionArrayList
            );
            saveKonfigurationDataObject(response.data);
            saveDiagnoseDataObject(response.data);
            setOpenAlertSuccess(true);
            setInputID("");
          });
        })
        .catch(() => {
          setOpenAlertError(true);
          delay(800).then(() => {
            setProgressSchaeffler(false);
            setProgressStadler(false);
            setProgressSBB(false);

            setErrorInput(true);
          });
        });
      axios({
        url: process.env.REACT_APP_BACKEND_SERVER + "/getpdf/" + inputID,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        setFilePDF(new Blob([response.data]));
        setFilePathPDFMontage("/mounting.pdf");
        setFilePathPDFWerkzeug("/Tools.pdf")
      });
    };
    console.log("original inputID: " + inputID);
    const type = checkTypeOfID(inputID);
    var harmonizedInputId = "";
    // if (type == "L-urn") {
    //   harmonizedInputId = harmonizeInputId(inputID);
    //   console.log("harmonized inputID: " + harmonizedInputId)
    // }
    if (type != "") {
      harmonizedInputId = harmonizeInputId(inputID);
      console.log("type: " + type + ", harmonized inputID: " + harmonizedInputId)
    }
    
    if (type.includes("L-")) {
      
      getInfosByUsingLagerSGTIN(harmonizedInputId);
    };
    if (type.includes("R-")) {
      const sendRadsatzSGTINToServer = (id) => {
        setProgressStadler(true);
        axios
          .get(process.env.REACT_APP_BACKEND_SERVER + "/getInfosByUsingRadsatzID", {
            params: {
              radsatzID: id,
            },
          })

          .then((response) => {
            delay(1500).then(() => {
              resetLagerInfo();
              setErrorInput(false);
              setProgressSchaeffler(false);
              setProgressStadler(false);
              setProgressSBB(false);
              setInputID("");
              saveKonfigurationDataObject(response.data);
              saveDiagnoseDataObject(response.data);
              setOpenAlertSuccess(true);
            });
          })
          .catch((error) => {
            setOpenAlertError(true);

            delay(800).then(() => {
              setProgressSBB(false);
              setProgressSchaeffler(false);
              setProgressStadler(false);
            });
          });
      };

      sendRadsatzSGTINToServer(harmonizedInputId);
    }
    if (type.includes("D-")) {
      const sendDrehgestellSGTINToServer = (id) => {
        setProgressStadler(true);
        axios
          .get(process.env.REACT_APP_BACKEND_SERVER + "/getInfosByUsingDrehgestellID", {
            params: {
              drehgestellID: id,
            },
          })

          .then((response) => {
            delay(1500).then(() => {
              resetLagerInfo();

              setErrorInput(false);
              setProgressSchaeffler(false);
              setProgressStadler(false);
              setProgressSBB(false);
              setInputID("");
              saveKonfigurationDataObject(response.data);
              saveDiagnoseDataObject(response.data);
              setOpenAlertSuccess(true);
            });
          })
          .catch((error) => {
            setOpenAlertError(true);

            delay(800).then(() => {
              setProgressSBB(false);
              setProgressSchaeffler(false);
              setProgressStadler(false);
            });
          });
      };

      sendDrehgestellSGTINToServer(harmonizedInputId);
    }
    if (type.includes("W-")) {
      const sendWagonIDToServer = (id) => {
        setProgressStadler(true);

        axios
          .get(process.env.REACT_APP_BACKEND_SERVER + "/getInfosByUsingWagonID", {
            params: {
              wagonID: id,
            },
          })

          .then((response) => {
            delay(1500).then(() => {
              resetLagerInfo();

              setErrorInput(false);
              setProgressSchaeffler(false);
              setProgressStadler(false);
              setProgressSBB(false);
              setInputID("");
              saveKonfigurationDataObject(response.data);
              saveDiagnoseDataObject(response.data);
              setOpenAlertSuccess(true);
            });
          })
          .catch((error) => {
            setOpenAlertError(true);

            delay(800).then(() => {
              setProgressSBB(false);
              setProgressSchaeffler(false);
              setProgressStadler(false);
            });
          });
      };

      sendWagonIDToServer(harmonizedInputId);
    }
    if (inputID.length > 42) {
      setOpenAlertErrorSyntax(true);
      setErrorInput(true);
    }
  }, [inputID, sendIDFromGarantieFall]);
  const handleClickPickShow4Lagers = (radsatzID) => {};
  const handleClickPickAnotherLager = (lagerID) => {
    setPairValueColor(lagerID);
    setProgressSchaeffler(true);
    const sendLagerSGTINToServer = () => {
      setProgressSchaeffler(true);
      setProgressSBB(true);
      axios
        .get(process.env.REACT_APP_BACKEND_SERVER + "/getInfosByUsingLagerSGTIN", {
          params: {
            lagerSGTIN: lagerID,
          },
        })

        .then((response) => {
          delay(1500).then(() => {
            setErrorInput(false);
            setProgressSchaeffler(false);
            setProgressStadler(false);
            setProgressSBB(false);
            saveLagerInfoDataObject(response.data);
            saveHistorikDataObject(
              response.data.historikDaten.revisionArrayList
            );
            //saveKonfigurationDataObject(response.data);
            saveDiagnoseDataObject(response.data);
            setOpenAlertSuccess(true);
            setInputID("");
          });
        })
        .catch(() => {
          setOpenAlertError(true);

          delay(800).then(() => {
            setProgressSBB(false);
            setProgressSchaeffler(false);
            setProgressStadler(false);
          });
        });
    };
    sendLagerSGTINToServer();
  };
  const handleClosePDF = () => {
    setOpenPDF(false);
  };
  const handleOpenPDF = () => {
    setOpenPDF(true);
  };
  const handleClosePDFWerkzeug = () => {
    setOpenPDFWerkzeug(false);
  };
  const handleOpenPDFWerkzeug = () => {
    setOpenPDFWerkzeug(true);
  };
  const handleClosePDFMontage = () => {
    setOpenPDFMontage(false);
  };
  const handleOpenPDFMontage = () => {
    setOpenPDFMontage(true);
  };

  return (
    <Box sx={{ flexGrow: 1 }} className={classes.root}>
      <Header
        inputIDinGarantieFall={inputID}
        inputID={inputID}
        setInputID={setInputID}
        setSendLagerSGTINFromKonfigurationFall={
          setSendLagerFromKonfigurationFall
        }
        setSendLagerSGTINFromGarantieFall={setSendLagerFromGarantieFall}
        sendIDFromKonfigurationFall={sendIDFromKonfigurationFall}
        setInputInGarantieFall={setInputID}
        sendLagerSGTINFromGarantieFall={sendIDFromGarantieFall}
        setProgressSchaeffler={setProgressSchaeffler}
        setProgressSBB={setProgressSBB}
        setProgressStadler={setProgressStadler}
        errorAxiosGarantieFall={errorInput}
      ></Header>
      <Grid className={classes.mainContainer}>
        <PDFDialog
          openPDF={openPDF}
          handleClosePDF={handleClosePDF}
          filePDF={filePDF}
        />
        <PDFDialogMontage
          openPDF={openPDFMontage}
          handleClosePDF={handleClosePDFMontage}
          filePDF={filePathPDFMontage}
        />
        <PDFDialogWerkzeug
          openPDF={openPDFWerkzeug}
          handleClosePDF={handleClosePDFWerkzeug}
          filePDF={filePathPDFWerkzeug}
        />
        <HistorikDataDialog
          openHistorik={openHistorik}
          handleCloseHistorik={handleCloseHistorik}
          rows={historikDataRows}
        />

        <RadsatzMontageDialog
          openMontage={openMontage}
          handleCloseMontage={handleCloseMontage}
          radsatzIndex={radsatzIndex}
        />
        <Grid container spacing={2}>
          <Grid item xs={4} handy={12}>
            <SchaefflerComponent
              lagerSGTIN={inputID}
              errorValid={errorInput}
              progress={progressSchaeffler}
              sendLagerSGTIN={setSendLagerFromGarantieFall}
              classes={classes}
              rows={lagerInfo}
              pairValueColor={pairValueColor}
              handleClosePDF={handleClosePDF}
              handleOpenPDF={handleOpenPDF}
              handleClosePDFWerkzeug={handleClosePDFWerkzeug}
              handleOpenPDFWerkzeug={handleOpenPDFWerkzeug}
              handleClosePDFMontage={handleClosePDFMontage}
              handleOpenPDFMontage={handleOpenPDFMontage}
              openPDF={openPDF}
              handleOpenHistorik={handleOpenHistorik}
              qr={qr}
            />
          </Grid>
          <Grid item xs={4} handy={12}>
            <StadlerComponent
              handleClickPickAnotherLager={handleClickPickAnotherLager}
              lagerSGTIN={inputID}
              errorValid={errorInput}
              progress={progressStadler}
              classes={classes}
              sendLagerSGTIN={setSendLagerFromKonfigurationFall}
              rows={konfiguration}
              pairValueColor={pairValueColor}
              handleClickPickShow4Lagers={handleClickPickShow4Lagers}
              handleOpenMontage={handleOpenMontage}
            />
          </Grid>
          <Grid item xs={4} handy={12}>
            <SbbComponent
              pairValueColor={pairValueColor}
              lagerSGTIN={inputID}
              progress={progressSBB}
              classes={classes}
              rows={diagnoseDaten}
            />
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={openAlertSuccess}
        autoHideDuration={2000}
        onClose={handleCloseSuccess}
        style={{ marginLeft: "40vw" }}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          Die Daten wurden erfolgreich abgerufen !
        </Alert>
      </Snackbar>
      <Snackbar
        open={openAlertError}
        autoHideDuration={1000}
        onClose={handleCloseErrorAxios}
        style={{ marginLeft: "40vw" }}
      >
        <Alert
          onClose={handleCloseErrorAxios}
          severity="error"
          sx={{ width: "100%" }}
        >
          Bei der Bearbeitung mit diesem ID ist ein Fehler aufgetreten !
        </Alert>
      </Snackbar>
      <Snackbar
        open={openAlertErrorSyntax && !openAlertSuccess}
        autoHideDuration={1000}
        onClose={handleCloseErrorSyntax}
        style={{ marginLeft: "40vw" }}
      >
        <Alert
          onClose={handleCloseErrorSyntax}
          severity="error"
          sx={{ width: "100%" }}
        >
          Syntax Fehler / Ungültige ID
        </Alert>
      </Snackbar>
      <Footer />
    </Box>
  );
};
export default App;
