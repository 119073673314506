import {
  AppBar,
  Grid,
  Box,
  TextField,
  Button,
  Autocomplete,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import LanguageIcon from "@mui/icons-material/Language";
import React from "react";
import { useTranslation, i18n } from "react-i18next";

/**
 * Best-Practice wenn man mit Darkmode und Grids arbeiten möchte und das bevorzugute Styling von Material-UI nutzt.
 */
const useStyles = makeStyles((theme) => ({
  appbar: {
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      height: "12.2rem",
      padding: "15px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      height: "8rem",
      padding: "10px",
      marginBottom: "1rem",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: "7rem",
      padding: "10px",
      marginBottom: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "7rem",
      padding: "10px",
    },
  },
  paper: {
    height: "13vh",
  },
  gs1Icon: {
    height: "90%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "0%",
      width: "0%",
    },
  },
  eeccIcon: {
    height: "90%",
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      height: "0%",
      width: "0%",
    },
  },
  inputBox: {
    backgroundColor: theme.palette.background.inputBox,
    [theme.breakpoints.up("lg")]: {
      height: "7h",
    },
    [theme.breakpoints.between("md", "lg")]: {
      height: "6vh",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: "12vh",
    },
    [theme.breakpoints.down("sm")]: {
      height: "12vh",
    },
  },
  inputfield: {
    height: "4vh",
  },
  languageIcon: {
    textAlign: "right",
  },
  languageButton: {
    marginBottom: "0.5rem",
  },
  titleBox: {
    color: theme.palette.text.title,
    backgroundColor: theme.palette.background.titleBox,
    textAlign: "center",
    fontWeight: "bold",
    border: "2px solid " + theme.palette.borderColor,

    [theme.breakpoints.up("lg")]: {
      height: "4.5rem",
      fontSize: "3rem",
      marginBottom: "1vh",
    },
    [theme.breakpoints.between("md", "lg")]: {
      height: "3rem",
      fontSize: "2rem",
      marginBottom: "1vh",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: "2rem",
      fontSize: "1.4rem",
      marginBottom: "1vh",
    },
    [theme.breakpoints.down("sm")]: {
      height: "1rem",
      fontSize: "0.7rem",
      marginBottom: "1vh",
    },
  },
  konfiguration: {
    margin: "auto",
    paddingTop: "0.6vh",
    border: "6px solid " + theme.palette.borderColor,
    padding: "0 !important",
  },
  garantieFall: {
    margin: "auto",
    paddingTop: "0.6vh",
    border: "6px solid " + theme.palette.borderColor,
    padding: "0 !important",
    width: "inherit",
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.between("md", "lg")]: {},
    [theme.breakpoints.between("sm", "md")]: {},
    [theme.breakpoints.down("sm")]: {},
  },
  inputFieldLabel: {
    fontWeight: "bold",
    fontSize: "150%",
    textAlign: "center",
    color: "black",
    marginTop: "0.1em",
    [theme.breakpoints.down("sm")]: {
      /*   marginLeft: "15rem",
      marginRight: "15rem", */
    },
  },
  textField: {
    width: "94%",
    textAlign: "center",
  },
}));

const Footer = (props) => {
  const classes = useStyles();
  const {
    inputIDinGarantieFall,
    setSendLagerSGTINFromGarantieFall,
    setInputInGarantieFall,
    sendLagerSGTINFromGarantieFall,
    errorAxiosGarantieFall,
  } = props;

  const handlePasteGarantieFall = () => {
    console.log("paste");
    /* setLagerSGTINFromGarantieFall(
      (e.clipboardData || window.clipboardData).getData("text")
    ); */

    /* setProgressSchaeffler(true);
    delay(800).then(() => {
      setProgressStadler(true);
      delay(800).then(() => {
        setProgressSBB(true);
      });
    }); */
    setSendLagerSGTINFromGarantieFall(!sendLagerSGTINFromGarantieFall);
  };
  const [anchorElLng, setAnchorElLng] = React.useState(null);
  const [iconLanguge, setIconLanguge] = React.useState(
    "/icons8-germany-40.png"
  );

  const handleChangeLng = (e) => {
    switch (e.target.innerText) {
      case "Deutsch":
        i18n.changeLanguage("de-DE");
        setIconLanguge("/icons8-germany-40.png");
        break;
      case "Englisch":
        i18n.changeLanguage("en-US");
        setIconLanguge("/icons8-usa-40.png");
        break;
      case "Français":
        i18n.changeLanguage("fr-FR");
        setIconLanguge("/icons8-france-40.png");
        break;
      default:
        return;
    }
    setAnchorElLng(null);
  };
  const handleOpen = (e) => {
    setAnchorElLng(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorElLng(null);
  };
  const { t, i18n } = useTranslation();

  const lngMenuItems = [
    {
      title: "Deutsch",
    },
    {
      title: "Englisch",
    },
    {
      title: "Français",
    },
  ];
  return (
    <AppBar position="absolut" className={classes.appbar}>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <Box className={classes.paper}>
            <img
              alt="gs1"
              src="./gs1Icon.jpg"
              className={classes.gs1Icon}
            ></img>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Box className={classes.paper}>
            <Grid className={classes.titleBox} container>
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                {t("proofOfConceptDigitaleWartung")}
              </Grid>
              <Grid item className={classes.languageIcon} xs={2}>
                <IconButton
                  onClick={handleOpen}
                  style={{ padding: "0 1rem 0.5rem" }}
                >
                  <img
                    style={{ maxWidth: "2rem", maxHeight: "2rem" }}
                    src={iconLanguge}
                  ></img>
                  {/*  <LanguageIcon
                    
                    
                  /> */}
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorElLng}
                  keepMounted
                  open={Boolean(anchorElLng)}
                  onClose={handleClose}
                >
                  {lngMenuItems.map((item) => (
                    <MenuItem
                      onClick={(item) => handleChangeLng(item)}
                      key={item.title}
                      value={item.title}
                      style={{ width: "5vw" }}
                    >
                      {item.title}
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            </Grid>

            <Box className={classes.inputBox}>
              <Grid
                container
                xs={12}
                spacing={7}
                className
                style={{
                  margin: "auto",
                  width: "82vw",
                  height: "5vh",
                }}
              >
                <Grid
                  item
                  justify="flex-end"
                  /*  xs={12} */
                  className={classes.garantieFall}
                >
                  <Grid container style={{ margin: "0.4rem" }}>
                    <Grid item sm={4} xs={4} handy={4}>
                      <Grid
                        className={classes.inputFieldLabel}
                        /*  style={{
                          fontWeight: "bold",
                          fontSize: "150%",
                          textAlign: "center",
                          color: "black",
                          marginTop: "0.1em",
                        }} */
                      >
                        {t("Eingabe")}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={8}
                      xs={8}
                      handy={8}
                      style={{ paddingRight: "10px" }}
                    >
                      <Autocomplete
                        options={[
                          "urn:epc:id:sgtin:4012802.039447.S120002448"
                        ]}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            type="text"
                            className={classes.textField}
                            error={errorAxiosGarantieFall}
                            label={t("EingabeLabel")}
                            variant="outlined"
                            color="secondary"
                            value={inputIDinGarantieFall}
                          />
                        )}
                        onInputChange={(event, newValue) => {
                          setInputInGarantieFall(newValue);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/*   <Grid
                  item
                  xs={12}
                  justify="flex-end"
                  className={classes.konfiguration}
                >
                  {" "}
                  <Grid container style={{ margin: "0.4em" }}>
                    <Grid item sm={4}>
                      <Grid
                        style={{
                          fontWeight: "bold",
                          fontSize: "150%",
                          textAlign: "center",
                          color: "black",
                          marginTop: "0.1em",
                        }}
                      >
                        Eingabe
                      </Grid>
                    </Grid>
                    <Grid item sm={8}>
                      <TextField
                        size="small"
                        type="text"
                        style={{
                          width: "94%",
                          textAlign: "center",
                        }}
                        error={errorAxiosKonfigurationFall}
                        value={inputID}
                        onChange={(event) =>
                          setInputInKonfigurationFall(event.target.value)
                        }
                        onKeyDown={
                          inputID.length >= 42
                            ? handleAutoSendInKonfigurationFall
                            : null
                        }
                        label="Eingabe Radsatz-ID, Drehgestell-ID, Wagon-ID"
                        variant="outlined"
                        onPaste={handlePasteInKonfigurationFall}
                      />
                    </Grid>
                  </Grid>
                </Grid> */}
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box className={classes.paper}>
            {" "}
            <img
              alt="eecc"
              src="./eeccIcon.png"
              className={classes.eeccIcon}
            ></img>
          </Box>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Footer;
