import { makeStyles } from "@mui/styles";
import * as React from "react";
import axios from "axios";
import { useTranslation, i18n } from "react-i18next";

import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import {
  styled,
  Button,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from "@mui/material";
import { Document, Page } from "react-pdf";
import { useEffect } from "react";

const ColorButtonCancel = styled(Button)(({ theme }) => ({
  color: "white",
  fontWeight: "600",
  backgroundColor: "#0F2A47",
  padding: "0.7rem",

  "&:hover": {
    backgroundColor: "#C7372F",
    color: "#ffff",
  },
}));
const ColorButtonConfirm = styled(Button)(({ theme }) => ({
  color: "white",
  padding: "0.7rem",
  fontWeight: "600",
  backgroundColor: "#0F2A47",
  "&:hover": {
    backgroundColor: "#0E86D4",
    color: "#0F2A47",
  },
}));

const useStyles = makeStyles((theme) => ({
  diaglogAction: {
    padding: "1rem 1.5rem 1rem !important",
  },
}));
const PDFDialogWerkzeug = (props) => {
  const { openPDF, handleClosePDF, filePDF } = props;
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const handleNext = () => {
    if ((pageNumber + 1) % numPages === 0) {
      setPageNumber(1);
    } else {
      setPageNumber((pageNumber + 1) % numPages);
    }
  };
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Dialog
        open={openPDF}
        onClose={handleClosePDF}
        aria-labelledby="draggable-dialog-title"
        xs={{
          padding: "1rem !important",
        }}
      >
        <DialogTitle
          style={{
            cursor: "move",
            fontWeight: "bold",
            fontSize: "150%",
            marginLeft: "inherit",
          }}
          id="draggable-dialog-title"
        >
          {t("Werkzeug")}
        </DialogTitle>
        <DialogContent>
          <Document file={filePDF} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} height={1000} />
          </Document>
          <p>
            {t("Seite")} : {pageNumber}
          </p>
        </DialogContent>

        <DialogActions className={classes.diaglogAction}>
          <ColorButtonCancel autoFocus onClick={handleClosePDF}>
            {t("Abschließen")}
          </ColorButtonCancel>
          <ColorButtonConfirm autoFocus onClick={handleNext}>
            {t("NächsteSeite")}
          </ColorButtonConfirm>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PDFDialogWerkzeug;
