export function checkTypeOfID(id) {
  if (id.includes("V") && id.length === 28) {
    console.log("L-element");
    return "L-element";
  } else if (id.includes("urn:epc:id:sgtin:") && id.length === 42) {
    console.log("L-urn");

    return "L-urn";
  } else if (
    id.includes("SBB_") &&
    !id.includes("urn:epc:id:giai") &&
    id.length === 25
  ) {
    console.log("R-element");

    return "R-element";
  } else if (
    id.includes("SBB_") &&
    id.includes("urn:epc:id:giai") &&
    id.length === 38
  ) {
    console.log("R-urn");

    return "R-urn";
  } else if (
    id.includes("-") &&
    !id.includes("urn:epc:id:giai") &&
    id.length === 21
  ) {
    console.log("D-element");

    return "D-element";
  } else if (
    id.includes("-") &&
    id.includes("urn:epc:id:giai") &&
    id.length === 34
  ) {
    console.log("D-urn");

    return "D-urn";
  } else if (
    !id.includes("-") &&
    !id.includes("SBB_") &&
    !id.includes("V") &&
    id.length === 24
  ) {
    console.log("W-element");

    return "W-element";
  } else if (
    !id.includes("-") &&
    !id.includes("SBB_") &&
    !id.includes("V") &&
    id.length === 37 &&
    id.includes("urn:epc:id:giai")
  ) {
    console.log("W-urn");

    return "W-urn";
  } else if (id.includes("S") && !id.includes("V") && id.length === 35) {
    return "L-urn";
  } else {
    console.log("no type id.length ", id.length);

    return "";
  }
}
export function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
export function harmonizeInputId(input) {
  //if input is urn return as is
  if (input.includes("sgtin")) return input;

  const gtinPrefix = '01';
  const lotPrefix = '10';
  const serialPrefix = '21';

  let gtin = '';
  let lot = '';
  let serial = '';
  
  let currentIndex = 0;

  while (currentIndex < input.length) {
      const prefix = input.substr(currentIndex, 2);

      if (prefix === gtinPrefix) {
          gtin = input.substr(currentIndex + 2, 14);
          currentIndex += 16;
      } else if (prefix === lotPrefix) {
          // Find the serialPrefix or end of string (index = -1)
          const nextPrefixIndex = input.indexOf(serialPrefix, currentIndex + 2);
          if (nextPrefixIndex === -1) {
              lot = input.substr(currentIndex + 2);
              currentIndex = input.length;
          } else {
              lot = input.substr(currentIndex + 2, nextPrefixIndex - currentIndex - 2);
              currentIndex = nextPrefixIndex;
          }
      } else if (prefix === serialPrefix) {
          serial = input.substr(currentIndex + 2, 10);
          currentIndex += 12;
      } else {
          console.error(`Unexpected prefix '${prefix}' during id harmonization`);
          return;
      }
  }
  return "01" + gtin + "21" + serial + "10" + lot;
}
