import { useMediaQuery } from "react-responsive";
import AppDesktop from "./AppDesktop";
import AppMobile from "./AppMobile";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
const MainApp = () => {
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 599px)",
  });
  const isDesktop = useMediaQuery({
    query: "(min-device-width: 600px)",
  });
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppDesktop />} />
        <Route path="/01/:gtin/21/:serial_number" element={<AppMobile />} />
      </Routes>
    </BrowserRouter>
  );
};
export default MainApp;
